/**
 * This file contains all the french translations for the keys used in the application
 */
export default {
  /* OTHERS */
  ERROR_404: "Cette page n'existe pas.",
  BACK_TO_DPCR_HOME: "Retour à l'accueil DPCR",
  NAVIGATE_BACK: "Retour",
  POURCENTAGE_RESULT: "{{value}}%",
  /* ADMINISTRATION_HOME */
  ADMINISTRATION_HOME_TITLE_DESCRIPTION: "Administration",
  ADMINISTRATION_HOME_TITLE: "Administration",
  ADMINISTRATION_HOME_CARD_USERS_TITLE: "Utilisateurs",
  ADMINISTRATION_HOME_CARD_USERS_SUBTITLE: "Gestion des utilisateurs",
  /* ADMINISTRATION_USERS */
  ADMINISTRATION_USERS_TITLE_DESCRIPTION:
    "Administration - Gestion des utilisateurs",
  ADMINISTRATION_USERS_TITLE: "Administration - Gestion des utilisateurs",
  ADMINISTRATION_USERS_ADD_NEW_USER_BUTTON: "Ajouter utilisateur",
  ADMINISTRATION_USERS_TABLE_HEADER_TITLE: "Tous les utilisateurs",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_zero: "{{count}} Utilisateur",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_one: "{{count}} Utilisateur",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_other: "{{count}} Utilisateurs",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_BUTTON: "Filtrer",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_LABEL: "Filtrer par groupe",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_PLACEHOLDER:
    "Sélectionner un groupe",
  ADMINISTRATION_USERS_TABLE_COLUMN_NAME: "Nom",
  ADMINISTRATION_USERS_TABLE_COLUMN_MAIL: "Adresse mail",
  ADMINISTRATION_USERS_TABLE_COLUMN_ROLES: "Roles",
  ADMINISTRATION_USERS_TABLE_COLUMN_CREATION_DATE: "Date de création",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY: "Actif",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY_ACTIVE: "Actif",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY_INACTIVE: "Inactif",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_BUTTON_LABEL:
    "Actions pour l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE:
    "Activer l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_DEACTIVATE:
    "Désactiver l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE_SUCCESS_TOAST_MESSAGE:
    "L'utilisateur a été mis à jour avec succès",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur est survenue pendant la mise à jour de l'utilisateur. Veuillez réessayez.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_MANAGE_GROUPS:
    "Gestion des rôles",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_TITLE:
    "Gestion des rôles de l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_SUBTITLE:
    "Choisissez les rôles pour cet utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_SUCCESS_TOAST_MESSAGE:
    "Rôle de l'utilisateur supprimé avec succès",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_ERROR_TOAST_MESSAGE:
    "Une erreur est survenue pendant la suppression du rôle de l'utilisateur. Veuillez réessayez.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_SUCCESS_TOAST_MESSAGE:
    "Rôle de l'utilisateur ajouté avec succès",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_ERROR_TOAST_MESSAGE:
    "Une erreur est survenue pendant l'ajout du rôle de l'utilisateur. Veuillez réessayez.",
  /* ADMINISTRATION USERS CREATION */
  ADMINISTRATION_USERS_CREATION_TITLE_DESCRIPTION:
    "Création d'un nouvel utilisateur",
  ADMINISTRATION_USERS_CREATION_TITLE: "Création d'un nouvel utilisateur",
  ADMINISTRATION_USERS_CREATION_SUBTITLE:
    "Définissez-ici les informations concernant l'utilisateur : son identité, adresse mail et les rôles que vous souhaitez lui accorder.",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_TITLE: "Identité",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_SUBTITLE:
    "Informations sur l'identité de l'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_FIRSTNAME_LABEL: "Prénom",
  ADMINISTRATION_USERS_CREATION_FORM_LASTNAME_LABEL: "Nom",
  ADMINISTRATION_USERS_CREATION_FORM_USERNAME_LABEL: "Nom d'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_TITLE: "Adresse mail",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_SUBTITLE:
    "Informations concernant l'adresse mail de l'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_LABEL: "Adresse mail",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_TITLE: "Rôles",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_SUBTITLE:
    "Choisissez les rôles accordés à l'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_LABEL: "Rôles",
  ADMINISTRATION_USERS_CREATION_CANCEL_BUTTON_LABEL: "Annuler",
  ADMINISTRATION_USERS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Créer l'utilisateur",
  ADMINISTRATION_USERS_CREATION_SUCCESS_TOAST_MESSAGE:
    "Utilisateur créé avec succès",
  /* ADMINISTRATION USERS ROLES */
  ROLE_ADMINISTRATOR: "Administrateur",
  ROLE_TEST_MANAGER: "Test manager",
  ROLE_USER: "Utilisateur",
  /* ANALYSIS CREATION */
  ANALYSIS_CREATION_TITLE_DESCRIPTION: "Création d'une nouvelle analyse",
  ANALYSIS_CREATION_TITLE: "Création d'une nouvelle analyse",
  ANALYSIS_CREATION_STEPPER_STEP1_TITLE: "Informations générales",
  ANALYSIS_CREATION_STEPPER_STEP2_TITLE: "Règles de qualité",
  ANALYSIS_CREATION_STEPPER_STEP3_TITLE: "Fréquence Allélique",
  ANALYSIS_CREATION_STEPPER_STEP4_TITLE: "Limites de Blanc et de Décision",
  ANALYSIS_CREATION_STEPPER_STEP5_TITLE: "Règles de décision",
  ANALYSIS_CREATION_STEPPER_STEP6_TITLE: "Contrôles des blancs",
  ANALYSIS_CREATION_STEPPER_STEP7_TITLE: "Concentration",
  ANALYSIS_CREATION_STEPPER_STEP8_TITLE: "Sensibilité",
  ANALYSIS_CREATION_STEPPER_STEP9_TITLE: "Incertitude",
  ANALYSIS_CREATION_STEPPER_SUBTITLE_TODO: "À compléter",
  ANALYSIS_CREATION_STEPPER_SUBTITLE_CURRENT: "En cours",
  ANALYSIS_CREATION_STEPPER_SUBTITLE_DONE: "Validé",
  ANALYSIS_CREATION_STEP1_SUBTITLE:
    "Définissez-ici les informations principales de l'analyse : nom, la version, le matériel utilisé (logiciel, kit, puce) et si l'analyse est en mode 'Production' ou 'Développement'",
  ANALYSIS_CREATION_VALIDATE_STEP_SUBMIT_BUTTON: "Valider les informations",
  ANALYSIS_CREATION_FORM_NAME_LABEL: "Nom de l'analyse",
  ANALYSIS_CREATION_FORM_VERSION_LABEL: "Numéro de version",
  ANALYSIS_CREATION_FORM_ADD_NEW_KIT: "Ajouter un kit",
  ANALYSIS_CREATION_FORM_KIT_LABEL: "Kit",
  ANALYSIS_CREATION_FORM_KIT_PLACEHOLDER: "Sélectionner un kit",
  ANALYSIS_CREATION_FORM_KIT_NO_OPTION: "Aucun kit trouvé",
  ANALYSIS_CREATION_FORM_ADD_NEW_CHIP: "Ajouter une puce",
  ANALYSIS_CREATION_FORM_CHIP_LABEL: "Puce",
  ANALYSIS_CREATION_FORM_CHIP_PLACEHOLDER: "Sélectionner une puce",
  ANALYSIS_CREATION_FORM_CHIP_NO_OPTION: "Aucune puce trouvée",
  ANALYSIS_CREATION_FORM_ADD_NEW_SOFTWARE: "Ajouter un logiciel",
  ANALYSIS_CREATION_FORM_SOFTWARE_LABEL: "Logiciel",
  ANALYSIS_CREATION_FORM_SOFTWARE_PLACEHOLDER: "Sélectionner un logiciel",
  ANALYSIS_CREATION_FORM_SOFTWARE_NO_OPTION: "Aucun logiciel trouvé",
  ANALYSIS_CREATION_FORM_MODE_LABEL: "Mode",
  ANALYSIS_CREATION_FORM_MODE_OPTION_DEVELOPMENT: "Développement",
  ANALYSIS_CREATION_FORM_MODE_OPTION_PRODUCTION: "Production",
  /* ANALYSES LIST */
  ANALYSES_LIST_TITLE_DESCRIPTION: "Liste des analyses",
  ANALYSES_LIST_TITLE: "Liste des analyses",
  ANALYSES_LIST_COUNT_LABEL_zero: "{{count}} analyse",
  ANALYSES_LIST_COUNT_LABEL_one: "{{count}} analyse",
  ANALYSES_LIST_COUNT_LABEL_other: "{{count}} analyses",
  ANALYSES_LIST_SEARCH_ANALYSE_LABEL: "Rechercher une analyse",
  ANALYSES_LIST_ADD_NEW_ANALYSE_BUTTON: "Ajouter une analyse",
  ANALYSES_LIST_CARD_KIT_LABEL: "Kit",
  ANALYSES_LIST_CARD_CHIP_LABEL: "Puce",
  ANALYSES_LIST_CARD_VERSION_LABEL: "Version",
  /* APP HOME */
  APP_HOME_TITLE_DESCRIPTION: "Application Geeng",
  APP_HOME_TITLE: "Application Geeng",
  APP_HOME_DPCR_LINK_TITLE: "App. DPCR",
  /* BREADCRUMBS */
  BREADCRUMBS_ADMINISTRATION: "Administration",
  BREADCRUMBS_ANALYSES: "Analyses",
  BREADCRUMBS_ANALYSES_ID: "Analyse - {{id}}",
  BREADCRUMBS_CHIPS: "Puces",
  BREADCRUMBS_CHIPS_ID: "Puce - {{id}}",
  BREADCRUMBS_CREATE: "Ajout",
  BREADCRUMBS_DEFAULT_ID: "ID - {{id}}",
  BREADCRUMBS_EDIT: "Édition",
  BREADCRUMBS_KITS: "Kits",
  BREADCRUMBS_KITS_ID: "Kit - {{id}}",
  BREADCRUMBS_NOTIFICATIONS: "Notifications",
  BREADCRUMBS_RUNS: "Runs",
  BREADCRUMBS_SAMPLE_ID: "Échantillon - {{id}}",
  BREADCRUMBS_SERIES: "Séries",
  BREADCRUMBS_SERIES_ID: "Série - {{id}}",
  BREADCRUMBS_SOFTWARES: "Logiciels",
  BREADCRUMBS_USERS: "Utilisateurs",
  /* CALCULATION */
  VAF: "Calcul de la VAF",
  SENSIBILITY: "Calcul de la sensitivité",
  UNCERTAINTY: "Incertitude",
  CALCULATION_TOOLTIP_ERROR:
    "{{type}}: une erreur est survenue pendant le calcul",
  /* CHIPS CREATION */
  CHIPS_CREATION_TITLE_DESCRIPTION: "Création d'une puce",
  CHIPS_CREATION_CANCEL_BUTTON_LABEL: "Retour à la liste des puces",
  CHIPS_CREATION_TITLE: "Création d'une puce",
  CHIPS_CREATION_SUBTITLE:
    "Définissez-ici les informations sur la puce : un nom, le fournisseur, un numéro de version et ses caractéristiques physiques",
  CHIPS_CREATION_SUCCESSFUL_TOAST_MESSAGE: "Puce créée avec succès",
  CHIPS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Créer la puce",
  CHIPS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  CHIPS_CREATION_FORM_INFORMATIONS_SUBTITLE:
    "Informations générales de la puce",
  CHIPS_CREATION_FORM_FORMAT_NB_CHAMBERS_SUMMARY: "Nb puits",
  CHIPS_CREATION_FORM_NAME_LABEL: "Nom",
  CHIPS_CREATION_FORM_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_CREATION_FORM_SUPPLIER_NO_OPTION: "Aucun fournisseur trouvé",
  CHIPS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Sélectionner un fournisseur",
  CHIPS_CREATION_FORM_VERSION_LABEL: "Version",
  CHIPS_CREATION_FORM_FORMAT_TITLE: "Format de la puce",
  CHIPS_CREATION_FORM_FORMAT_SUBTITLE: "Caractéristiques physiques de la puce",
  CHIPS_CREATION_FORM_NB_COLS_LABEL: "Nombre de colonnes",
  CHIPS_CREATION_FORM_NB_ROWS_LABEL: "Nombre de lignes",
  CHIPS_CREATION_FORM_NB_MAX_PARTITIONS_LABEL: "Partitions par puit",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_LABEL: "Volume de la partition",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_UNIT_LABEL: "nL",
  CHIPS_CREATION_FORM_INPUT_VOLUME_LABEL: "Volume de réaction",
  CHIPS_CREATION_FORM_INPUT_VOLUME_UNIT_LABEL: "μL",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_TITLE: "Règles de contrôles",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUBTITLE:
    "Quantité de partitions suffisante pour valider la conformité des échantillons",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_LABEL: "Quantité de partitions requise",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUPERIOR_OR_EQUAL_LABEL: "≥",
  CHIPS_CREATION_CONFIRMATION_MODAL_TITLE: "Création d'une puce",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer la puce {{chipName}}. Veuillez vérifier les informations saisies avant de confirmer la création.",
  CHIPS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Nom",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_LABEL: "Format",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_zero:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_one:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_other:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puits",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_LABEL: "V. partition",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_VALUE: "{{value}} nL",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_LABEL: "V. réaction",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_VALUE: "{{value}} μL",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_LABEL:
    "Conformité des contrôles qualité",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_one:
    "à partir de {{count}} partition",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_other:
    "à partir de {{count}} partitions",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirmer la création",
  /* CHIPS DETAILS */
  CHIPS_DETAILS_CHIP_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Puce non trouvée. Retour à la liste des puces",
  CHIPS_DETAILS_TITLE_DESCRIPTION: "Informations de la puce '{{chipName}}'",
  CHIPS_DETAILS_TITLE: "Informations de la puce '{{chipName}}'",
  CHIPS_DETAILS_INFORMATIONS_TITLE: "Informations",
  CHIPS_DETAILS_INFORMATIONS_SUBTITLE: "Informations générales sur la puce",
  CHIPS_DETAILS_NAME_LABEL: "Nom",
  CHIPS_DETAILS_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_DETAILS_VERSION_LABEL: "Version",
  CHIPS_DETAILS_CREATION_LABEL: "Création",
  CHIPS_DETAILS_CREATION_DATE_VALUE: "Le {{date}}",
  CHIPS_DETAILS_CREATION_VALUE: "Le {{date}}, par {{user}}",
  CHIPS_DETAILS_LAST_MODIFICATION_LABEL: "Dernière modification",
  CHIPS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "Le {{date}}",
  CHIPS_DETAILS_LAST_MODIFICATION_VALUE: "Le {{date}}, par {{user}}",
  CHIPS_DETAILS_FORMAT_TITLE: "Format de la puce",
  CHIPS_DETAILS_FORMAT_SUBTITLE: "Caractéristiques physiques de la puce",
  CHIPS_DETAILS_FORMAT_NB_CHAMBERS_SUMMARY: "Nb puits",
  CHIPS_DETAILS_FORMAT_LABEL: "Format",
  CHIPS_DETAILS_FORMAT_VALUE_zero:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_DETAILS_FORMAT_VALUE_one: "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_DETAILS_FORMAT_VALUE_other:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puits",
  CHIPS_DETAILS_INPUT_VOLUME_LABEL: "V. réaction",
  CHIPS_DETAILS_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_DETAILS_PARTITION_VOLUME_LABEL: "V. partition",
  CHIPS_DETAILS_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_DETAILS_QUALITY_CONTROL_LABEL: "Conformité des contrôles qualité",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_zero:
    "à partir de {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_one:
    "à partir de {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_other:
    "à partir de {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_TITLE: "Règles de contrôles",
  CHIPS_DETAILS_QUALITY_CONTROL_SUBTITLE:
    "Quantité de partitions suffisante pour valider la conformité des échantillons",
  CHIPS_DETAILS_EDIT_BUTTON_LABEL: "Modifier la puce",
  CHIPS_DETAILS_BACK_TO_CHIPS_LIST_BUTTON_LABEL: "Retour à la liste des puces",
  /* CHIPS EDITION */
  CHIPS_EDITION_TITLE_DESCRIPTION: "Édition d'une puce",
  CHIPS_EDITION_CANCEL_BUTTON_LABEL: "Retour à la liste des puces",
  CHIPS_EDITION_TITLE: "Édition d'une puce",
  CHIPS_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Puce modifiée avec succès",
  /* CHIPS LITS */
  CHIPS_LIST_TITLE_DESCRIPTION: "Liste des puces",
  CHIPS_LIST_TITLE: "Liste des puces",
  CHIPS_LIST_ADD_NEW_CHIP_BUTTON: "Ajouter une puce",
  CHIPS_LIST_SEARCH_CHIP_LABEL: "Rechercher une puce",
  CHIPS_LIST_COUNT_LABEL_zero: "{{count}} puce",
  CHIPS_LIST_COUNT_LABEL_one: "{{count}} puce",
  CHIPS_LIST_COUNT_LABEL_other: "{{count}} puces",
  CHIPS_LIST_HEADER_ALL_CHIPS: "Toutes les puces",
  CHIPS_LIST_HEADER_ACTIVE_CHIPS: "Les puces actives",
  CHIPS_LIST_FILTER_ALL_CHIPS: "Toutes",
  CHIPS_LIST_FILTER_ACTIVE_CHIPS: "Actives",
  CHIPS_LIST_FILTER_TABS_LABEL: "Filtrer les puces par activité",
  CHIPS_LIST_CARD_NAME_LABEL: "Nom",
  CHIPS_LIST_CARD_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_LIST_CARD_FORMAT_LABEL: "Format",
  CHIPS_LIST_CARD_FORMAT_VALUE_zero:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_LIST_CARD_FORMAT_VALUE_one:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_LIST_CARD_FORMAT_VALUE_other:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puits",
  CHIPS_LIST_CARD_PARTITIONS_NUMBER_LABEL: "Partitions par puit",
  CHIPS_LIST_CARD_INPUT_VOLUME_LABEL: "V. réaction",
  CHIPS_LIST_CARD_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_LIST_CARD_PARTITION_VOLUME_LABEL: "V. partition",
  CHIPS_LIST_CARD_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_LIST_CARD_IS_ACTIVE: "Active",
  CHIPS_LIST_CARD_IS_INACTIVE: "Archivée",
  CHIPS_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour la puce",
  CHIPS_LIST_CARD_MENU_NEW_VERSION: "Nouvelle version",
  CHIPS_LIST_CARD_MENU_ACTIVATE: "Activer",
  CHIPS_LIST_CARD_MENU_DEACTIVATE: "Archiver",
  CHIPS_LIST_CARD_MENU_DELETE: "Supprimer",
  CHIPS_LIST_CARD_VERSION_LABEL: "Version",
  CHIPS_LIST_CARD_QUALITY_CONTROL_LABEL: "Conformité des contrôles qualité",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_zero:
    "à partir de {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_one:
    "à partir de {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_other:
    "à partir de {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  CHIPS_LIST_CARD_DELETION_MODAL_TITLE:
    "Suppression de la puce\n'{{chipName}}'",
  CHIPS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer la puce '{{chipName}}' ?",
  CHIPS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer la puce",
  CHIPS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Puce supprimée avec succès",
  CHIPS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de la puce. Veuillez réessayer.",
  CHIPS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Puce activée avec succès",
  CHIPS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'activation de la puce. Veuillez réessayer.",
  CHIPS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Puce désactivée avec succès",
  CHIPS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la désactivation de la puce. Veuillez réessayer.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Création d'une nouvelle version de la puce\n'{{chipName}}'",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer une nouvelle version de la puce '{{chipName}}'. Renseignez le nouveau numéro de version puis validez.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "Nouvelle version",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON:
    "Créer nouvelle version",
  CHIPS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "Nouvelle version de la puce créée avec succès",
  CHIPS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la création de la nouvelle version de la puce. Veuillez réessayer.",
  /* CONTACT */
  CONTACT_TITLE_DESCRIPTION: "Contacter l'équipe Geeng",
  CONTACT_TITLE: "Contacter l'équipe Geeng",
  CONTACT_SUBTITLE:
    "Remplissez le formulaire de contact. Nous reviendrons vers vous le plus rapidement possible.",
  CONTACT_FORM_USER_INFORMATIONS_TITLE: "Informations",
  CONTACT_FORM_USER_INFORMATIONS_SUBTITLE: "Informations sur l'utilisateur",
  CONTACT_FORM_NAME_LABEL: "Nom",
  CONTACT_FORM_MAIL_LABEL: "Adresse mail",
  CONTACT_FORM_MESSAGE_TITLE: "Votre message",
  CONTACT_FORM_SUBMIT_BUTTON_LABEL: "Envoyer",
  CONTACT_FORM_SUCCESSFUL_TOAST_MESSAGE:
    "Message envoyé avec succès (aucune action en base de données pour le moment)",
  /* DPCR HOME */
  DPCR_HOME_TITLE_DESCRIPTION: "Application DPCR",
  DPCR_HOME_TITLE: "Application DPCR",
  /* DPCR_SIDEBAR */
  DPCR_SIDEBAR_SEARCH_INPUT_HELPERTEXT: "Rechercher",
  DPCR_SIDEBAR_SEARCH_INPUT_LABEL:
    "Rechercher par numéro d'échantillon ou mutation",
  DPCR_SIDEBAR_SEARCH_INPUT_NO_OPTION: "Aucun résultat trouvé",
  DPCR_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS: "Séries / Runs",
  DPCR_SIDEBAR_ITEMS_RUNS_LIST: "Liste Runs",
  DPCR_SIDEBAR_ITEMS_SERIES_LIST: "Liste Séries",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_CREATE: "Créer nouvelle série",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_UPLOAD_RESULTS: "Upload des résultats",
  DPCR_SIDEBAR_ITEMS_ANALYSES: "Analyses",
  DPCR_SIDEBAR_ITEMS_ANALYSES_LIST: "Liste des Analyses",
  DPCR_SIDEBAR_ITEMS_ANALYSES_BATCH_MANAGEMENT: "Gestion des Lots",
  DPCR_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  DPCR_SIDEBAR_ITEMS_SETTINGS: "Configuration",
  DPCR_SIDEBAR_ITEMS_SETTINGS_ANALYSIS_CREATE: "Créer nouvelle analyse",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_SEE: "Liste des kits",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_CREATE: "Créer nouveau kit",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_SEE: "Liste des puces",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_CREATE: "Créer nouvelle puce",
  DPCR_SIDEBAR_ITEMS_SETTINGS_SOFTWARES_SEE: "Liste des logiciels",
  DPCR_SIDEBAR_ITEMS_ADMINISTRATION: "Administration",
  DPCR_SIDEBAR_ITEMS_LOGOUT: "Déconnexion",
  /* FORM */
  FORM_REQUIRED_FIELD: "Champ requis",
  FORM_REQUIRED_AT_LEAST_ONE_ELEMENT:
    "Ce champ doit contenir au moins 1 élément",
  FORM_NO_OPTION_FOUND: "Aucune option trouvée",
  CANCEL: "Annuler",
  /* INTERPRETATIONS */
  MUTATED_zero: "Muté",
  MUTATED_one: "Muté",
  MUTATED_other: "Mutés",
  UNMUTATED_zero: "Non Muté",
  UNMUTATED_one: "Non Muté",
  UNMUTATED_other: "Non Mutés",
  /* KITS CREATION */
  KITS_CREATION_TITLE_DESCRIPTION: "Création d'un kit",
  KITS_CREATION_TITLE: "Création d'un kit",
  KITS_CREATION_SUBTITLE:
    "Définissez-ici les informations sur le kit : un nom, le fournisseur, un numéro de version, la liste des matrices d'échantillons sur lesquelles il peut être utilisé et la liste des cibles.",
  KITS_CREATION_FORM_NAME_LABEL: "Nom",
  KITS_CREATION_FORM_SUPPLIER_LABEL: "Fournisseur",
  KITS_CREATION_FORM_SUPPLIER_NO_OPTION: "Aucun fournisseur trouvé",
  KITS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Sélectionner un fournisseur",
  KITS_CREATION_FORM_VERSION_LABEL: "Version",
  KITS_CREATION_FORM_MATRICES_LABEL: "Matrices",
  KITS_CREATION_FORM_MATRICES_PLACEHOLDER: "Sélectionner les matrices",
  KITS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  KITS_CREATION_FORM_INFORMATIONS_SUBTITLE: "Informations générales sur le kit",
  KITS_CREATION_FORM_TARGETS_LABEL: "Nom de la cible",
  KITS_CREATION_FORM_TARGETS_TITLE: "Cibles",
  KITS_CREATION_FORM_TARGETS_SUBTITLE: "Liste des cibles couvertes par le kit",
  KITS_CREATION_FORM_TARGETS_NO_OPTION: "Aucune cible trouvée",
  KITS_CREATION_FORM_TARGETS_PLACEHOLDER: "Renseigner son nom",
  KITS_CREATION_FORM_TARGETS_ADD_BUTTON: "Ajouter une cible",
  KITS_CREATION_FORM_TARGETS_TARGET_INDEX: "Cible n°{{index}}",
  KITS_CREATION_FORM_TARGETS_SUBMIT: "Créer le kit",
  KITS_CREATION_BACK_TO_KITS_LIST_BUTTON: "Retour à la liste des kits",
  KITS_CREATION_FORM_TARGETS_SUMMARY_zero: "{{count}} Cible",
  KITS_CREATION_FORM_TARGETS_SUMMARY_one: "{{count}} Cible",
  KITS_CREATION_FORM_TARGETS_SUMMARY_other: "{{count}} Cibles",
  KITS_CREATION_SUCCESS_TOAST_MESSAGE: "Kit créé avec succès",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_zero:
    "Création d'un kit avec {{count}} Cible",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_one:
    "Création d'un kit avec {{count}} Cible",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_other:
    "Création d'un kit avec {{count}} Cibles",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "Vous êtes sur le point de créer le kit {{kitName}} avec un panel de {{count}} Cible. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_one:
    "Vous êtes sur le point de créer le kit {{kitName}} avec un panel de {{count}} Cible. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_other:
    "Vous êtes sur le point de créer le kit {{kitName}} avec un panel de {{count}} Cibles. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KITS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Nom",
  KITS_CREATION_CONFIRMATION_MODAL_TARGETS_LABEL: "Nb Cibles",
  KITS_CREATION_CONFIRMATION_MODAL_VERSION_LABEL: "Version",
  KITS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirmer la création",
  /* KITS DETAILS */
  KITS_DETAILS_TITLE_DESCRIPTION: "Informations du kit '{{kitName}}'",
  KITS_DETAILS_TITLE: "Informations du kit '{{kitName}}'",
  KITS_DETAILS_INFORMATIONS_TITLE: "Informations",
  KITS_DETAILS_INFORMATIONS_SUBTITLE: "Informations générales sur le kit",
  KITS_DETAILS_NAME_LABEL: "Nom",
  KITS_DETAILS_SUPPLIER_LABEL: "Fournisseur",
  KITS_DETAILS_VERSION_LABEL: "Version",
  KITS_DETAILS_MATRICES_LABEL: "Matrices",
  KITS_DETAILS_CREATION_LABEL: "Création",
  KITS_DETAILS_CREATION_DATE_VALUE: "Le {{date}}",
  KITS_DETAILS_CREATION_VALUE: "Le {{date}}, par {{user}}",
  KITS_DETAILS_LAST_MODIFICATION_LABEL: "Dernière modification",
  KITS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "Le {{date}}",
  KITS_DETAILS_LAST_MODIFICATION_VALUE: "Le {{date}}, par {{user}}",
  KITS_DETAILS_TARGETS_TITLE: "Toutes les cibles du kit",
  KITS_DETAILS_TARGETS_COUNT_LABEL_zero: "{{count}} Cible",
  KITS_DETAILS_TARGETS_COUNT_LABEL_one: "{{count}} Cible",
  KITS_DETAILS_TARGETS_COUNT_LABEL_other: "{{count}} Cibles",
  KITS_DETAILS_BACK_TO_KITS_LIST_BUTTON: "Retour à la liste des kits",
  KITS_DETAILS_KIT_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Kit non trouvé. Retour à la liste des kits",
  /* KITS LITS */
  KITS_LIST_TITLE_DESCRIPTION: "Liste des kits",
  KITS_LIST_TITLE: "Liste des kits",
  KITS_LIST_ADD_NEW_KIT_BUTTON: "Ajouter un kit",
  KITS_LIST_FILTER_TABS_LABEL: "Filtrer les kits par activité",
  KITS_LIST_HEADER_ALL_KITS: "Tous les kits",
  KITS_LIST_HEADER_ACTIVE_KITS: "Les kits actifs",
  KITS_LIST_FILTER_ALL_KITS: "Tous",
  KITS_LIST_FILTER_ACTIVE_KITS: "Actifs",
  KITS_LIST_SEARCH_KIT_LABEL: "Rechercher un kit",
  KITS_LIST_COUNT_LABEL_zero: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_one: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_other: "{{count}} kits",
  KITS_LIST_CARD_NAME_LABEL: "Nom",
  KITS_LIST_CARD_SUPPLIER_LABEL: "Fournisseur",
  KITS_LIST_CARD_TARGETS_LABEL: "Nb Cibles",
  KITS_LIST_CARD_MATRICES_LABEL: "Matrices",
  KITS_LIST_CARD_VERSION_LABEL: "Version",
  KITS_LIST_CARD_IS_ACTIVE: "Actif",
  KITS_LIST_CARD_IS_INACTIVE: "Archivé",
  KITS_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour le kit",
  KITS_LIST_CARD_MENU_NEW_VERSION: "Nouvelle version",
  KITS_LIST_CARD_MENU_DEACTIVATE: "Archiver",
  KITS_LIST_CARD_MENU_ACTIVATE: "Activer",
  KITS_LIST_CARD_MENU_DELETE: "Supprimer",
  KITS_LIST_CARD_MENU_CREATE_FROM: "Créer à partir de",
  KITS_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  KITS_LIST_CARD_DELETION_MODAL_TITLE: "Suppression du kit\n'{{kitName}}'",
  KITS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer le kit '{{kitName}}' ?",
  KITS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer le kit",
  KITS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Kit supprimé avec succès",
  KITS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression du kit. Veuillez réessayer.",
  KITS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit activé avec succès",
  KITS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'activation du kit. Veuillez réessayer.",
  KITS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit désactivé avec succès",
  KITS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la désactivation du kit. Veuillez réessayer.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Création d'une nouvelle version du kit\n'{{kitName}}'",
  KITS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer une nouvelle version du kit {{kitName}}. Renseignez le nouveau numéro de version puis validez.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "Nouvelle version",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Créer nouvelle version",
  KITS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "Nouvelle version du kit créée avec succès",
  KITS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la création de la nouvelle version du kit. Veuillez réessayer.",
  /* LOGIN */
  LOGIN_TITLE_DESCRIPTION: "Connexion à Geeng",
  LOGIN_WELCOME_TITLE: "Heureux de vous revoir",
  LOGIN_WELCOME_SUBTITLE: "Entrez votre identifiant et\nvotre mot de passe",
  LOGIN_LANGUAGE_SELECTOR_LABEL: "Sélectionnez la langue de votre choix",
  LOGIN_FORM_ERROR_INVALID_PASSWORD_OR_IDENTIFIER:
    "Identifiant ou mot de passe incorrects",
  LOGIN_FORM_IDENTIFIER_LABEL: "Identifiant",
  LOGIN_FORM_IDENTIFIER_ERROR_REQUIRED: "L'identifiant est requis",
  LOGIN_FORM_PASSWORD_LABEL: "Mot de passe",
  LOGIN_FORM_PASSWORD_ERROR_REQUIRED: "Le mot de passe est requis",
  LOGIN_FORM_SUBMIT_BUTTON: "Connexion",
  LOGIN_FORGOTTEN_PASSWORD_BUTTON: "Mot de passe oublié ?",
  /* LOGO */
  LOGO_APP_TITLE: "Geeng",
  LOGO_CUSTOMER: "CHU de Rennes",
  title: "Bienvenue dans As We Care",
  /* LOGOUT */
  LOGOUT_TOKEN_EXPIRED_TOAST_MESSAGE:
    "Votre session a expiré, reconnectez-vous.",
  LOGOUT_SUCCESSFUL_TOAST_MESSAGE: "Vous êtes désormais déconnecté",
  /* MAIN APP SIDEBAR */
  MAIN_APP_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  MAIN_APP_SIDEBAR_ITEMS_APPLICATION_DPCR: "DPCR",
  MAIN_APP_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  /* MATRICES */
  MATRICES_CF_DNA: "cf-DNA",
  MATRICES_F_DNA: "f-DNA",
  /* QUALITY CONTROL RESULT */
  QUALITY_CONTROL_RESULT_PASS_LABEL: "Conforme",
  QUALITY_CONTROL_RESULT_FAIL_LABEL: "Non Conforme",
  QUALITY_CONTROL_RESULT_WARNING_LABEL: "À surveiller",
  /* RUNS */
  /* RUNS STATUS */
  RUN_STATUS_VALIDATED: "Validé",
  RUN_STATUS_READY: "Disponible",
  RUN_STATUS_WAITING: "En attente",
  RUN_STATUS_FAILED: "Erreur",
  RUN_STATUS_PROCESSING: "En cours",
  RUN_STATUS_QUEUED: "En cours",
  /* RUNS QC VALIDTION STATUS */
  RUN_QUALITY_CONTROL_VALIDATION_DONE:
    "Validation des Contrôles Qualité effectuée",
  RUN_QUALITY_CONTROL_VALIDATION_TODO:
    "Validation des Contrôles Qualité à faire",
  /* RUNS CONTROLS TYPE */
  TYPE_BLANK_CONTROL: "Contrôle Blanc",
  TYPE_BLANK_CONTROL_SHORT: "TB",
  TYPE_NEG_CONTROL: "Contrôle TN",
  TYPE_NEG_CONTROL_SHORT: "TN",
  TYPE_POS_CONTROL: "Contrôle TPC",
  TYPE_POS_CONTROL_SHORT: "TP",
  QC_CONTROLS_TOOLTIP:
    "Résultat pour le contrôle qualité {{type}} : {{result}}",
  QC_CONTROLS_TOOLTIP_ERROR:
    "Une erreur est survenue pendant le calcul du résultat de ce contrôle qualité",
  QC_CONTROLS_TOOLTIP_TRUE_zero: "conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_TRUE_one: "conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_TRUE_other: "conforme, {{count}} partitions positives",
  QC_CONTROLS_TOOLTIP_FALSE_zero: "non conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_FALSE_one: "non conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_FALSE_other:
    "non conforme, {{count}} partitions positives",
  /* RUNS_IMPORT */
  RUNS_IMPORT_BUTTON_LABEL: "Ajouter des Runs",
  RUNS_IMPORT_TITLE: "Import de fichiers de résultats de Runs",
  RUNS_IMPORT_TITLE_FOR_SERIE:
    "Import de fichiers de résultats de Runs pour la Série {{serie}}",
  RUNS_IMPORT_SUBTITLE:
    "Sélectionner la série concernée puis déposer ou sélectionner les fichiers de résultats que vous souhaitez charger dans l'application. Vous pourrez ensuite sélectionner pour chaque fichier de résultats, le numéro de lot des témoins de contrôle utilisé, et éventuellement renommer le nom du Run.",
  RUNS_IMPORT_SUBTITLE_FOR_SERIE:
    "Déposer ou sélectionner les fichiers de résultats que vous souhaitez charger dans l'application. Vous pourrez ensuite sélectionner pour chaque fichier de résultats, le numéro de lot des témoins de contrôle utilisé, et éventuellement renommer le nom du Run.",
  RUNS_IMPORT_DROP_AREA_SUPPORTED_FILES_LABEL:
    "Les fichiers supportés sont seulement : .xlsx et .xls",
  RUNS_IMPORT_DROP_AREA_SELECT_FILES_BUTTON_LABEL: "Cliquer pour sélectionner",
  RUNS_IMPORT_DROP_AREA_LABEL: "Fichiers de résultats",
  RUNS_IMPORT_DROP_AREA_INNER_TEXT: "ou déposer directement un fichier",
  RUNS_IMPORT_DROP_AREA_INNER_SUBTEXT: "Les fichiers supportés sont",
  RUNS_IMPORT_FORM_RUN_NAME_LABEL: "Nom du run",
  RUNS_IMPORT_FORM_RUN_ANALYSIS_LABEL: "Analyse",
  RUNS_IMPORT_FORM_RUN_ANALYSIS_NO_OPTION: "Aucune analyse trouvée",
  RUNS_IMPORT_FORM_RUN_ANALYSIS_PLACEHOLDER: "Sélectionner une analyse",
  RUNS_IMPORT_FORM_RUN_KIT_BATCH_LABEL: "Lot de kit",
  RUNS_IMPORT_FORM_RUN_KIT_BATCH_NO_OPTION: "Aucun lot de kit trouvé",
  RUNS_IMPORT_FORM_RUN_KIT_BATCH_PLACEHOLDER: "Sélectionner un lot de kit",
  RUNS_IMPORT_FORM_SERIE_LABEL: "Série",
  RUNS_IMPORT_FORM_SERIE_NO_OPTION: "Aucune série trouvée",
  RUNS_IMPORT_FORM_SERIE_PLACEHOLDER: "Sélectionner une série",
  RUNS_IMPORT_FORM_SUBMIT_BUTTON: "Valider et importer les résultats",
  RUNS_IMPORT_FORM_SUCCESS_TOAST_one:
    "Import du fichier de résultats effectué avec succès",
  RUNS_IMPORT_FORM_SUCCESS_TOAST_other:
    "Import des {{count}} fichiers de résultats effectué avec succès",
  RUNS_IMPORT_BACK_TO_RUNS_LIST_BUTTON: "Retour à la liste des Runs",
  /* RUNS LIST*/
  RUNS_LIST_TITLE_DESCRIPTION: "Liste des Runs",
  RUNS_LIST_TITLE: "Liste des Runs",
  RUNS_LIST_TABLE_HEADER_TITLE: "Tous nos runs",
  RUNS_LIST_TABLE_HEADER_BADGE_zero: "{{count}} Série",
  RUNS_LIST_TABLE_HEADER_BADGE_one: "{{count}} Série",
  RUNS_LIST_TABLE_HEADER_BADGE_other: "{{count}} Séries",
  RUNS_LIST_TABLE_HEADER_FILTER_BUTTON: "Filtrer",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_LABEL: "Filtre par analyse",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_PLACEHOLDER:
    "Sélectionner une analyse",
  RUNS_LIST_TABLE_HEADER_FILTER_STATE_LABEL: "Filtre par état",
  RUNS_LIST_TABLE_HEADER_FILTER_STATE_PLACEHOLDER: "Sélectionner un état",
  RUNS_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Filtre par date",
  RUNS_LIST_TABLE_HEADER_FILTER_DATE_PLACEHOLDER: "Sélectionner une date",
  RUNS_LIST_TABLE_COLUMN_SERIE: "Séries",
  RUNS_LIST_TABLE_COLUMN_SERIE_TOOLTIP: "Nombre d'échantillons traités",
  RUNS_LIST_TABLE_COLUMN_RUNS: "Runs",
  RUNS_LIST_TABLE_COLUMN_ANALYSIS: "Analyse",
  RUNS_LIST_TABLE_COLUMN_STATE: "État",
  RUNS_LIST_TABLE_COLUMN_DATE: "Date",
  RUNS_LIST_TABLE_COLUMN_EDIT_RUN_LINK:
    "Voir plus de détails à propos du run {{runId}}",
  /* SAMPLE DETAILS */
  SAMPLE_DETAILS_SAMPLE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Échantillon non trouvé. Retour à la list des échantillons de la série.",
  SAMPLE_DETAILS_BREADCRUMBS_BACK_LABEL: "Retour à la liste",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_NEXT_SAMPLE:
    "Échantillon suivant",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_BACK_TO_LIST:
    "Retour à la liste",
  SAMPLE_DETAILS_TITLE_DESCRIPTION: "Détails de l'échantillon {{sampleId}}",
  SAMPLE_DETAILS_TITLE: "Échantillon",
  SAMPLE_DETAILS_TITLE_STATUS: "Statut :",
  SAMPLE_DETAILS_HEADER_PATHOLOGY: "Pathologie",
  SAMPLE_DETAILS_HEADER_SAMPLE_TYPE: "S. Type",
  SAMPLE_DETAILS_HEADER_CELL_QUANTITY: "Q. Cell.",
  SAMPLE_DETAILS_HEADER_ANALYSES: "Analyses",
  SAMPLE_DETAILS_ACTION_BUTTON_LABEL: "Actions",
  SAMPLE_DETAILS_ACTION_MENU_EXPORT: "Export",
  SAMPLE_DETAILS_EXPORT_MODAL_TITLE: "Export des données",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS: "Informations",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS_SAMPLE_ID: "ID Échantillon",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_zero: "{{count}} cible",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_one: "{{count}} cible",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_other: "{{count}} cibles",
  SAMPLE_DETAILS_EXPORT_MODAL_SUBMIT_LABEL: "Exporter",
  SAMPLE_DETAILS_EXPORT_SUCCESS_TOAST_MESSAGE: "Export effectué avec succès",
  SAMPLE_DETAILS_EXPORT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'export des données. Veuillez réessayer.",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS: "Analyse",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS_STATUS: "Statut",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL: "QC",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_PASS: "Passe",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_NOT_PASS:
    "Ne passe pas",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_CHAMBER: "Puits",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_SAMPLE: "Échantillon",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_PARTITIONS: "{{count}} part.",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_DNA: "ADN",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_DNA_QUANTITY: "{{quantity}} ng",
  SAMPLE_DETAILS_TABLE_COLUMN_TARGET: "Cible",
  SAMPLE_DETAILS_TABLE_COLUMN_BLANK_CONTROL: "TB",
  SAMPLE_DETAILS_TABLE_COLUMN_NEG_CONTROL: "TN",
  SAMPLE_DETAILS_TABLE_COLUMN_POS_CONTROL: "TP",
  SAMPLE_DETAILS_TABLE_COLUMN_PARTITIONS: "PART.",
  SAMPLE_DETAILS_TABLE_COLUMN_VAF: "VAF",
  SAMPLE_DETAILS_TABLE_COLUMN_SENSITIVITY: "Max Sens.",
  SAMPLE_DETAILS_TABLE_COLUMN_UNCERTAINTY: "Incert.",
  SAMPLE_DETAILS_TABLE_COLUMN_INTERPRETATION: "Interprétation",
  SAMPLE_DETAILS_TABLE_COLUMN_FLAGGED_TOOLTIP_DISABLED:
    "Impossible de déclarer en variant d'intérêt une cible non mutée",
  SAMPLE_DETAILS_TABLE_VALIDATE_RUN_RESULT: "Valider",
  SAMPLE_DETAILS_VALIDATED: "Validé",
  SAMPLE_DETAILS_UPDATE_RUN_SUCCESS_TOAST_MESSAGE:
    "Information mise à jour avec succès",
  SAMPLE_DETAILS_UPDATE_RUN_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour. Veuillez réessayer.",
  /* SAMPLE INTERPRETATION */
  UNKNOWN: "Non interprété",
  NON_CONTRIBUTIVE: "Non contributif",
  UNMUTATED: "Non muté",
  MUTATED: "Muté",
  /* SAMPLE POSITIVITY */
  SAMPLE_POSITIVITY_CANDIDATE: "Candidat",
  SAMPLE_POSITIVITY_POSITIVE: "Positif",
  /* SAMPLE PRIORITY */
  SAMPLE_PRIORITY_DEFAULT: "Routine",
  SAMPLE_PRIORITY_URGENT: "Urgent",
  /* SAMPLE STATUS */
  SAMPLE_STATUS_READY: "À faire",
  SAMPLE_STATUS_PROCESSING: "En cours",
  SAMPLE_STATUS_VALIDATED: "Validé",
  SAMPLE_STATUS_ERROR: "Erreur",
  /* SAMPLE TYPE */
  SAMPLE_TYPE_F_DNA: "free DNA",
  SAMPLE_TYPE_F_DNA_SHORT: "F",
  SAMPLE_TYPE_CF_DNA: "cell-free DNA",
  SAMPLE_TYPE_CF_DNA_SHORT: "CF",
  /* SAMPLESHEETS LIST */
  SAMPLESHEETS_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour la samplesheet",
  SAMPLESHEETS_LIST_CARD_MENU_DELETE: "Supprimer",
  SAMPLESHEETS_LIST_CARD_MENU_DOWNLOAD: "Télécharger",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_TITLE: "Suppression de la samplesheet",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer la samplesheet\n{{- samplesheetName}} ?",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON:
    "Supprimer la samplesheet",
  SAMPLESHEETS_LIST_DELETION_SUCCESS_TOAST_MESSAGE:
    "Samplesheet supprimée avec succès",
  SAMPLESHEETS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de la samplesheet. Veuillez réessayer.",
  /* SERIE CREATION */
  SERIES_CREATION_TITLE_DESCRIPTION: "Création d'une série",
  SERIES_CREATION_TITLE: "Création d'une série",
  SERIES_CREATION_SUBTITLE:
    "Pour commencer, nommez la série afin de l'initialiser. Vous pourrez ensuite charger les fichiers de samplesheets la concernant et ensuite les fichiers de résultats de Runs.",
  SERIES_CREATION_FORM_INFORMATIONS_TITLE: "Informations générales de la série",
  SERIES_CREATION_FORM_IDENTIFIER_LABEL: "Nom",
  SERIES_CREATION_FORM_SUBMIT: "Créer la série",
  SERIES_CREATION_BACK_TO_SERIES_LIST_BUTTON: "Retour à la liste des séries",
  SERIES_CREATION_FORM_SUCCESS_TOAST: "Série créée avec succès",
  /* SERIE DETAILS*/
  SERIES_DETAILS_TITLE: "Série",
  SERIES_DETAILS_TITLE_DESCRIPTION: "Détails de la série '{{serieName}}'",
  SERIES_DETAILS_SERIE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Série non trouvée. Retour à la liste des séries.",
  SERIES_DETAILS_RUN_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Run non trouvé pour la série. Retour à la page détails de la série.",
  /* SERIE DETAILS NO RESULTS*/
  SERIES_DETAILS_NO_RESULTS_TITLE: "Détails de la série",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_TITLE: "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_IMPORT_BUTTON_LABEL:
    "Ajouter des Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_zero:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_one:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_other:
    "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_ADD_DESCRIPTION:
    "Aucune Samplesheet transmise pour cette série.",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_TITLE: "Fichiers de résultats",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_RESULTS_NB: "Fichier de résultats",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_ADD_DESCRIPTION:
    "Aucun fichier de résultats transmis pour cette série.",
  /* SERIE DETAILS CONTROL VALIDATION*/
  SERIES_DETAILS_CONTROL_VALIDATION_TITLE: "Validation des contrôles",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_LABEL: "Runs de la série",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_zero: "Échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_one: "Échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_other:
    "Échantillons",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TITLE: "Analyse, Kit & Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_ANALYSIS_COLUMN_TITLE:
    "Analyse",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_SUPPLIER_COLUMN_TITLE:
    "Fournisseur",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_CHIP_COLUMN_TITLE:
    "Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_KIT_COLUMN_TITLE: "Kit",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_TARGETS_COLUMN_TITLE:
    "Cibles",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_zero: "{{count}} Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_one: "{{count}} Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_other:
    "{{count}} Puces",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_INDEX:
    "Puce n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_VALIDATION_TOOLTIP:
    "Validez ou non les Contrôles Qualité pour la puce n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_TABLE_COLUMN_HEADER_ID:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_zero:
    "{{count}} partition pour cet échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_one:
    "{{count}} partition pour cet échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_other:
    "{{count}} partitions pour cet échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_zero:
    "{{count}} partition attendue pour satisfaire la règle de contrôle qualité de la puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_one:
    "{{count}} partition attendue pour satisfaire la règle de contrôle qualité de la puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_other:
    "{{count}} partitions attendues pour satisfaire la règle de contrôle qualité de la puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Contrôle qualité de la puce mise à jour avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour du contrôle qualité de la puce. Veuillez réessayer.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Résultat du contrôle qualité lié à l'échantillon {{sampleId}} mis à jour avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour du résultat du contrôle qualité lié à l'échantillon {{sampleId}}. Veuillez réessayer.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TITLE:
    "Contrôle Qualité des Témoins",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_zero:
    "{{count}} Témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_one:
    "{{count}} Témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_other:
    "{{count}} Témoins",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_TYPE_COLUMN_TITLE:
    "Type de contrôle",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_BATCH_NUMBER:
    "N° Lot : {{batchNumber}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_ID_COLUMN_TITLE:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_PARTITIONS_NB_COLUMN_TITLE:
    "Nb partitions",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Résultat du contrôle qualité du témoin {{sampleId}} mis à jour avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour du résultat du contrôle qualité du témoin {{sampleId}}. Veuillez réessayer.",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON:
    "Valider les contrôles du run",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFOS_one:
    "Le statut suivant n'a pas été défini :",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFOS_other:
    "Les statuts suivants n'ont pas été définis :",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFO_CHIP:
    "Puce {{chipId}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFO_CONTROL:
    "Contrôle {{type}} (N° Lot {{batchNumber}})",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_TITLE:
    "Validation des contrôles qualité du run",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBTITLE:
    "Vous vous apprêtez à valider les contrôles qualité du run. Vous pouvez laisser un commentaire pour apporter des précisions.",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUMMARY_INFORMATIONS:
    "Informations",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_ANALYSIS: "Analyse",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_zero:
    "Nombre de puce",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_one:
    "Nombre de puce",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_other:
    "Nombre de puces",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_zero:
    "Nombre de témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_one:
    "Nombre de témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_other:
    "Nombre de témoins",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_VALIDATION_DATE:
    "Date de validation",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_OPERATOR: "Par",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_COMMENTS: "Commentaires",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBMIT:
    "Valider les contrôles du run",
  SERIES_DETAILS_CONTROL_VALIDATED:
    "Contrôles qualité validés le {{date}} par {{by}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Validation des contrôles qualité du run effectué avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la validation des contrôles qualité du run. Veuillez réessayer.",
  /* SERIES DETAIL SAMPLES LIST */
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE: "Tous les échantillons",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_zero: "{{count}} Échantillon",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_one: "{{count}} Échantillon",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_other: "{{count}} Échantillons",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_LABEL: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_NO_OPTION_MESSAGE:
    "Aucun Sample ID trouvé",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_LABEL: "Pathologie",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_NO_OPTION_MESSAGE:
    "Aucune pathologie trouvée",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_STATUS_LABEL: "Statut",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_RESET_LABEL: "Réinitialiser les filtres",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ID: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_TITLE: "Commentaires",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS:
    "Informations échantillon",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_SAMPLE_ID:
    "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_PATHOLOGY:
    "Pathologie",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_ANALYSES:
    "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_COMMENTS:
    "Les commentaires",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_FOLLOW_LINK:
    "Consulter",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PRIORITY: "Priorité",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PATHOLOGY: "Pathologie",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_SAMPLE_TYPE: "S. Type",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_CELL_QUANTITY: "Q. Cellulaire",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ANALYSES: "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_ANALYSES_TOOLTIP:
    "Interprétation pour l'analyse {{analysisName}} : {{interpretation}}",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_POSITIVITY: "Positivité",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_STATUS: "Statut",
  /* SERIES LIST */
  SERIES_LIST_TITLE_DESCRIPTION: "Liste des séries",
  SERIES_LIST_TITLE: "Liste des séries",
  SERIES_LIST_CREATE_SERIE_BUTTON: "Créer une nouvelle série",
  SERIES_LIST_TABLE_HEADER_TITLE: "Toutes nos séries",
  SERIES_LIST_TABLE_HEADER_BADGE_zero: "{{count}} série",
  SERIES_LIST_TABLE_HEADER_BADGE_one: "{{count}} série",
  SERIES_LIST_TABLE_HEADER_BADGE_other: "{{count}} séries",
  SERIES_LIST_TABLE_HEADER_FILTER_BUTTON: "Filtrer",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Filtre par date",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_PLACEHOLDER: "Sélectionner une date",
  SERIES_LIST_CARD_DATE_LABEL: "Date",
  SERIES_LIST_CARD_EXPECTED_SAMPLES_LABEL: "Samples attendus",
  SERIES_LIST_CARD_TREATED_SAMPLES_LABEL: "Samples traités",
  SERIES_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour la série",
  SERIES_LIST_CARD_MENU_ADD_NEW_SAMPLESHEET: "Ajouter samplesheet",
  SERIES_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  SERIES_LIST_CARD_DELETION_MODAL_TITLE:
    "Suppression de la série\n'{{serieName}}'",
  SERIES_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer la série '{{serieName}}' ?",
  SERIES_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer la série",
  SERIES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Série supprimée avec succès",
  SERIES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de la série. Veuillez réessayer.",
  /* SERIES SAMPLESHEETS IMPORT */
  SERIES_SAMPLESHEETS_IMPORT_TITLE:
    "Import de fichiers de samplesheet d'une Série",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_FOR_SERIE:
    "Import de fichiers de samplesheet de la Série {{serie}}",
  SERIES_SAMPLESHEETS_IMPORT_SUBTITLE:
    "Déposer ou sélectionner les fichiers de samplesheet en les associant à un ou plusieurs opérateurs.",
  SERIES_SAMPLESHEETS_IMPORT_BACK_TO_SERIES_BUTTON:
    "Retour à la liste des Séries",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_LABEL: "Opérateur(s)",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_NO_OPTION:
    "Aucun opérateur trouvé",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_PLACEHOLDER:
    "Sélectionner opérateur(s)",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SUPPORTED_FILES_LABEL:
    "Les fichiers supportés sont seulement : .xlsx et .xls",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SELECT_FILES_BUTTON_LABEL:
    "Cliquer pour sélectionner",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_LABEL: "Fichiers de samplesheets",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_TEXT:
    "ou déposer directement un fichier",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_SUBTEXT:
    "Les fichiers supportés sont",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUBMIT_BUTTON:
    "Valider et importer les samplesheets",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_one:
    "Import du fichier de samplesheet effectué avec succès",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_other:
    "Import des {{count}} fichiers de samplesheet effectué avec succès",
  SERIES_SAMPLESHEETS_IMPORT_BACK_TO_SERIES_LIST_BUTTON:
    "Retour à la liste des Séries",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_IMPORT_ERROR_MESSAGE:
    "Échec de l'import",
  /* SOFTWARES LITS */
  SOFTWARES_LIST_TITLE_DESCRIPTION: "Liste des logiciels",
  SOFTWARES_LIST_TITLE: "Liste des logiciels",
  SOFTWARES_LIST_SEARCH_SOFTWARE_LABEL: "Rechercher un logiciel",
  SOFTWARES_LIST_COUNT_LABEL_zero: "{{count}} logiciel",
  SOFTWARES_LIST_COUNT_LABEL_one: "{{count}} logiciel",
  SOFTWARES_LIST_COUNT_LABEL_other: "{{count}} logiciels",
  SOFTWARES_LIST_CONTACT_LABEL:
    "Vous souhaitez intégrer un nouveau logiciel dans l'application afin d'exploiter de nouveaux fichiers de résultats ? Prenez contact avec l'équipe Geeng",
  SOFTWARES_LIST_CONTACT_BUTTON_LABEL: "Contact",
};
