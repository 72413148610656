/**
 * This file contains all the english translations for the keys used in the application
 */
export default {
  /* OTHERS */
  ERROR_404: "This page does not exist.",
  BACK_TO_DPCR_HOME: "Back to DPCR Home",
  NAVIGATE_BACK: "Back",
  POURCENTAGE_RESULT: "{{value}}%",
  /* ADMINISTRATION_HOME */
  ADMINISTRATION_HOME_TITLE_DESCRIPTION: "Administration",
  ADMINISTRATION_HOME_TITLE: "Administration",
  ADMINISTRATION_HOME_CARD_USERS_TITLE: "Users",
  ADMINISTRATION_HOME_CARD_USERS_SUBTITLE: "Manage users",
  /* ADMINISTRATION_USERS */
  ADMINISTRATION_USERS_TITLE_DESCRIPTION: "Administration - Users management",
  ADMINISTRATION_USERS_TITLE: "Administration - Users management",
  ADMINISTRATION_USERS_ADD_NEW_USER_BUTTON: "Add user",
  ADMINISTRATION_USERS_TABLE_HEADER_TITLE: "All users",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_zero: "{{count}} User",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_one: "{{count}} User",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_other: "{{count}} Users",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_BUTTON: "Filter",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_LABEL: "Filter by group",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_PLACEHOLDER: "Select group",
  ADMINISTRATION_USERS_TABLE_COLUMN_NAME: "Name",
  ADMINISTRATION_USERS_TABLE_COLUMN_MAIL: "Mail address",
  ADMINISTRATION_USERS_TABLE_COLUMN_ROLES: "Roles",
  ADMINISTRATION_USERS_TABLE_COLUMN_CREATION_DATE: "Creation date",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY: "Active",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY_ACTIVE: "Active",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY_INACTIVE: "Inactive",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_BUTTON_LABEL:
    "Actions for user",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE: "Activate user",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_DEACTIVATE: "Deactivate user",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE_SUCCESS_TOAST_MESSAGE:
    "User successfully updated",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the user. Please retry.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_MANAGE_GROUPS_TITLE:
    "Manage roles",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_SUBTITLE:
    "Choose roles for the user",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_SUCCESS_TOAST_MESSAGE:
    "User's role successfully deleted",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the user's role. Please retry.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_SUCCESS_TOAST_MESSAGE:
    "User's role successfully added",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_ERROR_TOAST_MESSAGE:
    "An error has occurred during the addition of the user's role. Please retry.",
  /* ADMINISTRATION USERS CREATION */
  ADMINISTRATION_USERS_CREATION_TITLE_DESCRIPTION: "Create a new user",
  ADMINISTRATION_USERS_CREATION_TITLE: "Create a new user",
  ADMINISTRATION_USERS_CREATION_SUBTITLE:
    "Define here the informations about the user : identity, mail address and the roles granted",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_TITLE: "Identity",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_SUBTITLE:
    "Informations about user's identity",
  ADMINISTRATION_USERS_CREATION_FORM_FIRSTNAME_LABEL: "Firstname",
  ADMINISTRATION_USERS_CREATION_FORM_LASTNAME_LABEL: "Lastname",
  ADMINISTRATION_USERS_CREATION_FORM_USERNAME_LABEL: "Username",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_TITLE: "Mail address",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_SUBTITLE:
    "Informations about user's mail address",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_LABEL: "Mail address",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_TITLE: "Roles",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_SUBTITLE:
    "Choose the roles granted to the user",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_LABEL: "Roles",
  ADMINISTRATION_USERS_CREATION_CANCEL_BUTTON_LABEL: "Cancel",
  ADMINISTRATION_USERS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Create user",
  ADMINISTRATION_USERS_CREATION_SUCCESS_TOAST_MESSAGE:
    "User successfully created",
  /* ADMINISTRATION USERS ROLES */
  ROLE_ADMINISTRATOR: "Administrator",
  ROLE_TEST_MANAGER: "Test manager",
  ROLE_USER: "User",
  /* ANALYSIS CREATION */
  ANALYSIS_CREATION_TITLE_DESCRIPTION: "Create a new analysis",
  ANALYSIS_CREATION_TITLE: "Create a new analysis",
  ANALYSIS_CREATION_STEPPER_STEP1_TITLE: "General informations",
  ANALYSIS_CREATION_STEPPER_STEP2_TITLE: "Quality rules",
  ANALYSIS_CREATION_STEPPER_STEP3_TITLE: "Allelic Frequency",
  ANALYSIS_CREATION_STEPPER_STEP4_TITLE: "Limit of Blank and Decision",
  ANALYSIS_CREATION_STEPPER_STEP5_TITLE: "Decision rules",
  ANALYSIS_CREATION_STEPPER_STEP6_TITLE: "Blank control rules",
  ANALYSIS_CREATION_STEPPER_STEP7_TITLE: "Concentration",
  ANALYSIS_CREATION_STEPPER_STEP8_TITLE: "Sensibility",
  ANALYSIS_CREATION_STEPPER_STEP9_TITLE: "Uncertainty",
  ANALYSIS_CREATION_STEPPER_SUBTITLE_TODO: "To complete",
  ANALYSIS_CREATION_STEPPER_SUBTITLE_CURRENT: "In progress",
  ANALYSIS_CREATION_STEPPER_SUBTITLE_DONE: "Validated",
  ANALYSIS_CREATION_STEP1_SUBTITLE:
    "Define here the main informations of the analysis : name, used material (software, kit, chip) and if the analysis is in 'Production' or 'Development' mode",
  ANALYSIS_CREATION_VALIDATE_STEP_SUBMIT_BUTTON: "Validate informations",
  ANALYSIS_CREATION_FORM_NAME_LABEL: "Analysis name",
  ANALYSIS_CREATION_FORM_VERSION_LABEL: "Version number",
  ANALYSIS_CREATION_FORM_ADD_NEW_KIT: "Add new kit",
  ANALYSIS_CREATION_FORM_KIT_LABEL: "Kit",
  ANALYSIS_CREATION_FORM_KIT_PLACEHOLDER: "Select a kit",
  ANALYSIS_CREATION_FORM_KIT_NO_OPTION: "No kit found",
  ANALYSIS_CREATION_FORM_ADD_NEW_CHIP: "Add new chip",
  ANALYSIS_CREATION_FORM_CHIP_LABEL: "Chip",
  ANALYSIS_CREATION_FORM_CHIP_PLACEHOLDER: "Select a chip",
  ANALYSIS_CREATION_FORM_CHIP_NO_OPTION: "No chip found",
  ANALYSIS_CREATION_FORM_ADD_NEW_SOFTWARE: "Add new software",
  ANALYSIS_CREATION_FORM_SOFTWARE_LABEL: "Software",
  ANALYSIS_CREATION_FORM_SOFTWARE_PLACEHOLDER: "Select a software",
  ANALYSIS_CREATION_FORM_SOFTWARE_NO_OPTION: "No software found",
  ANALYSIS_CREATION_FORM_MODE_LABEL: "Mode",
  ANALYSIS_CREATION_FORM_MODE_OPTION_DEVELOPMENT: "Development",
  ANALYSIS_CREATION_FORM_MODE_OPTION_PRODUCTION: "Production",
  /* ANALYSES LIST */
  ANALYSES_LIST_TITLE_DESCRIPTION: "Analyses list",
  ANALYSES_LIST_TITLE: "Analyses list",
  ANALYSES_LIST_COUNT_LABEL_zero: "{{count}} analysis",
  ANALYSES_LIST_COUNT_LABEL_one: "{{count}} analysis",
  ANALYSES_LIST_COUNT_LABEL_other: "{{count}} analyses",
  ANALYSES_LIST_SEARCH_ANALYSE_LABEL: "Search analysis",
  ANALYSES_LIST_ADD_NEW_ANALYSE_BUTTON: "Add new analysis",
  ANALYSES_LIST_CARD_KIT_LABEL: "Kit",
  ANALYSES_LIST_CARD_CHIP_LABEL: "Chip",
  ANALYSES_LIST_CARD_VERSION_LABEL: "Version",
  /* APP HOME */
  APP_HOME_TITLE_DESCRIPTION: "Geeng Application",
  APP_HOME_TITLE: "Geeng Application",
  APP_HOME_DPCR_LINK_TITLE: "DPCR App.",
  /* BREADCRUMBS */
  BREADCRUMBS_ADMINISTRATION: "Administration",
  BREADCRUMBS_ANALYSES: "Analyses",
  BREADCRUMBS_ANALYSES_ID: "Analysis - {{id}}",
  BREADCRUMBS_CHIPS: "Chips",
  BREADCRUMBS_CHIPS_ID: "Chip - {{id}}",
  BREADCRUMBS_CREATE: "Add",
  BREADCRUMBS_DEFAULT_ID: "ID - {{id}}",
  BREADCRUMBS_EDIT: "Edition",
  BREADCRUMBS_KITS: "Kits",
  BREADCRUMBS_KITS_ID: "Kit - {{id}}",
  BREADCRUMBS_NOTIFICATIONS: "Notifications",
  BREADCRUMBS_RUNS: "Runs",
  BREADCRUMBS_SAMPLE_ID: "Sample - {{id}}",
  BREADCRUMBS_SERIES: "Series",
  BREADCRUMBS_SERIES_ID: "Serie - {{id}}",
  BREADCRUMBS_SOFTWARES: "Softwares",
  BREADCRUMBS_USERS: "Users",
  /* CALCULATION */
  VAF: "VAF calculation",
  SENSITIVITY: "Sensitivity calculation",
  UNCERTAINTY: "Uncertainty",
  CALCULATION_TOOLTIP_ERROR:
    "{{type}}: an error has occurred during the calculation of the result",
  /* CHIPS CREATION */
  CHIPS_CREATION_TITLE_DESCRIPTION: "Chip creation",
  CHIPS_CREATION_CANCEL_BUTTON_LABEL: "Back to chips list",
  CHIPS_CREATION_TITLE: "Chip creation",
  CHIPS_CREATION_SUBTITLE:
    "Define here the chip informations : a name, a supplier, a version number and its physical caracteristics",
  CHIPS_CREATION_SUCCESSFUL_TOAST_MESSAGE: "Chip successfully created",
  CHIPS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Create chip",
  CHIPS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  CHIPS_CREATION_FORM_INFORMATIONS_SUBTITLE: "Main informations about the chip",
  CHIPS_CREATION_FORM_NAME_LABEL: "Name",
  CHIPS_CREATION_FORM_SUPPLIER_LABEL: "Supplier",
  CHIPS_CREATION_FORM_SUPPLIER_NO_OPTION: "No supplier found",
  CHIPS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Select a supplier",
  CHIPS_CREATION_FORM_VERSION_LABEL: "Version",
  CHIPS_CREATION_FORM_FORMAT_TITLE: "Chip format",
  CHIPS_CREATION_FORM_FORMAT_SUBTITLE: "Physical caracteristics of the chip",
  CHIPS_CREATION_FORM_FORMAT_NB_CHAMBERS_SUMMARY: "Nb chambers",
  CHIPS_CREATION_FORM_NB_COLS_LABEL: "Number of columns",
  CHIPS_CREATION_FORM_NB_ROWS_LABEL: "Number of rows",
  CHIPS_CREATION_FORM_NB_MAX_PARTITIONS_LABEL: "Partitions per chamber",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_LABEL: "Partition volume",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_UNIT_LABEL: "nL",
  CHIPS_CREATION_FORM_INPUT_VOLUME_LABEL: "Reaction volume",
  CHIPS_CREATION_FORM_INPUT_VOLUME_UNIT_LABEL: "μL",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_TITLE: "Quality controls",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUBTITLE:
    "Sufficient quantity of partitions to validate sample conformity",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_LABEL: "Required partition quantity",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUPERIOR_OR_EQUAL_LABEL: "≥",
  CHIPS_CREATION_CONFIRMATION_MODAL_TITLE: "Creation of a chip",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBTITLE:
    "You are about to create the new chip '{{chipName}}'. Please check the entered informations before to confirm the creation.",
  CHIPS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Name",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUPPLIER_LABEL: "Supplier",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_LABEL: "Format",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_zero:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_one:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_other:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chambers",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_LABEL: "Partition V.",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_VALUE: "{{value}} nL",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_LABEL: "Reaction V.",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_VALUE: "{{value}} μL",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_LABEL:
    "Conformity of quality controls",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_one:
    "from {{count}} partition",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_other:
    "from {{count}} partitions",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm the creation",
  /* CHIPS DETAILS */
  CHIPS_DETAILS_CHIP_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Chip not found. Back to chips list",
  CHIPS_DETAILS_TITLE_DESCRIPTION: "Informations about the chip '{{chipName}}'",
  CHIPS_DETAILS_TITLE: "Informations about the chip '{{chipName}}'",
  CHIPS_DETAILS_INFORMATIONS_TITLE: "Informations",
  CHIPS_DETAILS_INFORMATIONS_SUBTITLE: "Main informations about the chip",
  CHIPS_DETAILS_NAME_LABEL: "Name",
  CHIPS_DETAILS_SUPPLIER_LABEL: "Supplier",
  CHIPS_DETAILS_VERSION_LABEL: "Version",
  CHIPS_DETAILS_CREATION_LABEL: "Creation",
  CHIPS_DETAILS_CREATION_DATE_VALUE: "The {{date}}",
  CHIPS_DETAILS_CREATION_VALUE: "The {{date}}, by {{user}}",
  CHIPS_DETAILS_LAST_MODIFICATION_LABEL: "Last modification",
  CHIPS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "The {{date}}",
  CHIPS_DETAILS_LAST_MODIFICATION_VALUE: "The {{date}}, by {{user}}",
  CHIPS_DETAILS_FORMAT_TITLE: "Chip format",
  CHIPS_DETAILS_FORMAT_SUBTITLE: "Physical caracteristics of the chip",
  CHIPS_DETAILS_FORMAT_NB_CHAMBERS_SUMMARY: "Nb chambers",
  CHIPS_DETAILS_FORMAT_LABEL: "Format",
  CHIPS_DETAILS_FORMAT_VALUE_zero:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_DETAILS_FORMAT_VALUE_one:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_DETAILS_FORMAT_VALUE_other:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chambers",
  CHIPS_DETAILS_INPUT_VOLUME_LABEL: "Reaction V.",
  CHIPS_DETAILS_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_DETAILS_PARTITION_VOLUME_LABEL: "Partition V.",
  CHIPS_DETAILS_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_DETAILS_QUALITY_CONTROL_LABEL: "Conformity of quality controls",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_zero:
    "from {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_one:
    "from {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_other:
    "from {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_TITLE: "Quality controls",
  CHIPS_DETAILS_QUALITY_CONTROL_SUBTITLE:
    "Sufficient quantity of partitions to validate sample conformity",
  CHIPS_DETAILS_EDIT_BUTTON_LABEL: "Edit the chip",
  CHIPS_DETAILS_BACK_TO_CHIPS_LIST_BUTTON_LABEL: "Back to chips list",
  /* CHIPS EDITION */
  CHIPS_EDITION_TITLE_DESCRIPTION: "Edit chip",
  CHIPS_EDITION_CANCEL_BUTTON_LABEL: "Back to chips list",
  CHIPS_EDITION_TITLE: "Edit chip",
  CHIPS_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Chip successfully edited",
  /* CHIPS LITS */
  CHIPS_LIST_TITLE_DESCRIPTION: "Chips list",
  CHIPS_LIST_TITLE: "Chips list",
  CHIPS_LIST_ADD_NEW_CHIP_BUTTON: "Add new chip",
  CHIPS_LIST_SEARCH_CHIP_LABEL: "Search chip",
  CHIPS_LIST_COUNT_LABEL_zero: "{{count}} chip",
  CHIPS_LIST_COUNT_LABEL_one: "{{count}} chip",
  CHIPS_LIST_COUNT_LABEL_other: "{{count}} chips",
  CHIPS_LIST_HEADER_ALL_CHIPS: "All chips",
  CHIPS_LIST_HEADER_ACTIVE_CHIPS: "Active chips",
  CHIPS_LIST_FILTER_ALL_CHIPS: "All",
  CHIPS_LIST_FILTER_ACTIVE_CHIPS: "Active",
  CHIPS_LIST_FILTER_TABS_LABEL: "Filter chips by activity",
  CHIPS_LIST_CARD_NAME_LABEL: "Name",
  CHIPS_LIST_CARD_SUPPLIER_LABEL: "Supplier",
  CHIPS_LIST_CARD_FORMAT_LABEL: "Format",
  CHIPS_LIST_CARD_FORMAT_VALUE_zero:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_LIST_CARD_FORMAT_VALUE_one:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_LIST_CARD_FORMAT_VALUE_other:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chambers",
  CHIPS_LIST_CARD_PARTITIONS_NUMBER_LABEL: "Partitions per chamber",
  CHIPS_LIST_CARD_INPUT_VOLUME_LABEL: "Reaction V.",
  CHIPS_LIST_CARD_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_LIST_CARD_IS_ACTIVE: "Active",
  CHIPS_LIST_CARD_IS_INACTIVE: "Archived",
  CHIPS_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the chip",
  CHIPS_LIST_CARD_MENU_NEW_VERSION: "New version",
  CHIPS_LIST_CARD_MENU_ACTIVATE: "Activate",
  CHIPS_LIST_CARD_MENU_DEACTIVATE: "Archive",
  CHIPS_LIST_CARD_MENU_DELETE: "Delete",
  CHIPS_LIST_CARD_VERSION_LABEL: "Version",
  CHIPS_LIST_CARD_QUALITY_CONTROL_LABEL: "Quality controls conformity",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_zero:
    "from {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_one:
    "from {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_other:
    "from {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_SEE_DETAILS_LINK: "See in details",
  CHIPS_LIST_CARD_DELETION_MODAL_TITLE: "Removal of the chip\n'{{chipName}}'",
  CHIPS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the chip '{{chipName}}' ?",
  CHIPS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the chip",
  CHIPS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Chip deleted successfully",
  CHIPS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the chip. Please retry.",
  CHIPS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Chip activated successfully",
  CHIPS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the activation of the chip. Please retry.",
  CHIPS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Chip archived successfully",
  CHIPS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the archiving of the chip. Please retry.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Creation of new version of the chip\n'{{chipName}}'",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "You are about to create a new version of the chip '{{chipName}}'. Enter the new version number and confirm.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "New version",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Create new version",
  CHIPS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "New chip version created successfully",
  CHIPS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the creation of a new version of the chip. Please retry.",
  /* CONTACT */
  CONTACT_TITLE_DESCRIPTION: "Contact Geeng team",
  CONTACT_TITLE: "Contact Geeng team",
  CONTACT_SUBTITLE: "Fill the contact form. We will come back to you quickly.",
  CONTACT_FORM_USER_INFORMATIONS_TITLE: "Informations",
  CONTACT_FORM_USER_INFORMATIONS_SUBTITLE: "User informations",
  CONTACT_FORM_NAME_LABEL: "Name",
  CONTACT_FORM_MAIL_LABEL: "Mail address",
  CONTACT_FORM_MESSAGE_TITLE: "Your message",
  CONTACT_FORM_SUBMIT_BUTTON_LABEL: "Send",
  CONTACT_FORM_SUCCESSFUL_TOAST_MESSAGE:
    "Message successfully sent (no backend action for now)",
  /* DPCR HOME */
  DPCR_HOME_TITLE_DESCRIPTION: "DPCR Application",
  DPCR_HOME_TITLE: "DPCR Application",
  /* DPCR_SIDEBAR */
  DPCR_SIDEBAR_SEARCH_INPUT_HELPERTEXT: "Search",
  DPCR_SIDEBAR_SEARCH_INPUT_LABEL: "Search by sample number or mutation",
  DPCR_SIDEBAR_SEARCH_INPUT_NO_OPTION: "No result found",
  DPCR_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS: "Series / Runs",
  DPCR_SIDEBAR_ITEMS_RUNS_LIST: "Runs list",
  DPCR_SIDEBAR_ITEMS_SERIES_LIST: "Series list",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_CREATE: "Create a new Serie",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_UPLOAD_RESULTS: "Upload results",
  DPCR_SIDEBAR_ITEMS_ANALYSES: "Analyses",
  DPCR_SIDEBAR_ITEMS_ANALYSES_LIST: "Analyses list",
  DPCR_SIDEBAR_ITEMS_ANALYSES_BATCH_MANAGEMENT: "Batch management",
  DPCR_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  DPCR_SIDEBAR_ITEMS_SETTINGS: "Set-up",
  DPCR_SIDEBAR_ITEMS_SETTINGS_ANALYSIS_CREATE: "Create new analysis",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_SEE: "Kits list",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_CREATE: "Create new kit",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_SEE: "Chips list",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_CREATE: "Create new chip",
  DPCR_SIDEBAR_ITEMS_SETTINGS_SOFTWARES_SEE: "Softwares list",
  DPCR_SIDEBAR_ITEMS_ADMINISTRATION: "Administration",
  DPCR_SIDEBAR_ITEMS_LOGOUT: "Log out",
  /* FORM */
  FORM_REQUIRED_FIELD: "Required field",
  FORM_REQUIRED_AT_LEAST_ONE_ELEMENT:
    "This field must contain at least 1 element",
  FORM_NO_OPTION_FOUND: "No option found",
  CANCEL: "Cancel",
  /* INTERPRETATIONS */
  MUTATED_zero: "Mutated",
  MUTATED_one: "Mutated",
  MUTATED_other: "Mutated",
  UNMUTATED_zero: "Unmutated",
  UNMUTATED_one: "Unmutated",
  UNMUTATED_other: "Unmutated",
  /* KITS CREATION */
  KITS_CREATION_TITLE_DESCRIPTION: "Kit creation",
  KITS_CREATION_BACK_TO_KITS_LIST_BUTTON: "Back to kits list",
  KITS_CREATION_TITLE: "Kit creation",
  KITS_CREATION_SUBTITLE:
    "Define here the kit informations : a name, a supplier, a version number, the sample matrices on which it can be used and the list of targets.",
  KITS_CREATION_FORM_NAME_LABEL: "Name",
  KITS_CREATION_FORM_SUPPLIER_LABEL: "Supplier",
  KITS_CREATION_FORM_SUPPLIER_NO_OPTION: "No supplier found",
  KITS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Select a supplier",
  KITS_CREATION_FORM_VERSION_LABEL: "Version",
  KITS_CREATION_FORM_MATRICES_LABEL: "Matrices",
  KITS_CREATION_FORM_MATRICES_PLACEHOLDER: "Select matrices",
  KITS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  KITS_CREATION_FORM_INFORMATIONS_SUBTITLE: "Main informations about the kit",
  KITS_CREATION_FORM_TARGETS_LABEL: "Target name",
  KITS_CREATION_FORM_TARGETS_TITLE: "Targets",
  KITS_CREATION_FORM_TARGETS_SUBTITLE: "List of targets covered by the kit",
  KITS_CREATION_FORM_TARGETS_NO_OPTION: "No target found",
  KITS_CREATION_FORM_TARGETS_PLACEHOLDER: "Fill its name",
  KITS_CREATION_FORM_TARGETS_ADD_BUTTON: "Add new target",
  KITS_CREATION_FORM_TARGETS_TARGET_INDEX: "Target n°{{index}}",
  KITS_CREATION_FORM_TARGETS_SUBMIT: "Create the kit",
  KITS_CREATION_FORM_TARGETS_SUMMARY_zero: "{{count}} Target",
  KITS_CREATION_FORM_TARGETS_SUMMARY_one: "{{count}} Target",
  KITS_CREATION_FORM_TARGETS_SUMMARY_other: "{{count}} Targets",
  KITS_CREATION_SUCCESS_TOAST_MESSAGE: "Kit created successfully",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_zero:
    "Creation of a kit with {{count}} Target",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_one:
    "Creation of a kit with {{count}} Target",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_other:
    "Creation of a kit with {{count}} Targets",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "You are about to create the kit '{{kitName}}' with a panel of {{count}} Target. Please check the entered informations before to confirm the creation.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_one:
    "You are about to create the kit '{{kitName}}' with a panel of {{count}} Target. Please check the entered informations before to confirm the creation.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_other:
    "You are about to create the kit '{{kitName}}' with a panel of {{count}} Targets. Please check the entered informations before to confirm the creation.",
  KITS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Name",
  KITS_CREATION_CONFIRMATION_MODAL_TARGETS_LABEL: "Targets Nb",
  KITS_CREATION_CONFIRMATION_MODAL_VERSION_LABEL: "Version",
  KITS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm the creation",
  /* KITS DETAILS */
  KITS_DETAILS_TITLE_DESCRIPTION: "Informations about the kit '{{kitName}}'",
  KITS_DETAILS_TITLE: "Informations about the kit '{{kitName}}'",
  KITS_DETAILS_INFORMATIONS_TITLE: "Informations",
  KITS_DETAILS_INFORMATIONS_SUBTITLE: "Main informations about the kit",
  KITS_DETAILS_NAME_LABEL: "Name",
  KITS_DETAILS_SUPPLIER_LABEL: "Supplier",
  KITS_DETAILS_VERSION_LABEL: "Version",
  KITS_DETAILS_MATRICES_LABEL: "Matrices",
  KITS_DETAILS_CREATION_LABEL: "Creation",
  KITS_DETAILS_CREATION_DATE_VALUE: "The {{date}}",
  KITS_DETAILS_CREATION_VALUE: "The {{date}}, by {{user}}",
  KITS_DETAILS_LAST_MODIFICATION_LABEL: "Last modification",
  KITS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "The {{date}}",
  KITS_DETAILS_LAST_MODIFICATION_VALUE: "The {{date}}, by {{user}}",
  KITS_DETAILS_TARGETS_TITLE: "All kit targets",
  KITS_DETAILS_TARGETS_COUNT_LABEL_zero: "{{count}} Target",
  KITS_DETAILS_TARGETS_COUNT_LABEL_one: "{{count}} Target",
  KITS_DETAILS_TARGETS_COUNT_LABEL_other: "{{count}} Targets",
  KITS_DETAILS_BACK_TO_KITS_LIST_BUTTON: "Back to kits list",
  KITS_DETAILS_KIT_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Kit not found. Back to kits list",
  /* KITS LITS */
  KITS_LIST_TITLE_DESCRIPTION: "Kits list",
  KITS_LIST_TITLE: "Kits list",
  KITS_LIST_ADD_NEW_KIT_BUTTON: "Add new kit",
  KITS_LIST_FILTER_TABS_LABEL: "Filter kits by activity",
  KITS_LIST_HEADER_ALL_KITS: "All kits",
  KITS_LIST_HEADER_ACTIVE_KITS: "Active kits",
  KITS_LIST_FILTER_ALL_KITS: "All",
  KITS_LIST_FILTER_ACTIVE_KITS: "Active",
  KITS_LIST_SEARCH_KIT_LABEL: "Search kit",
  KITS_LIST_COUNT_LABEL_zero: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_one: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_other: "{{count}} kits",
  KITS_LIST_CARD_NAME_LABEL: "Name",
  KITS_LIST_CARD_SUPPLIER_LABEL: "Supplier",
  KITS_LIST_CARD_TARGETS_LABEL: "Targets Nb",
  KITS_LIST_CARD_MATRICES_LABEL: "Matrices",
  KITS_LIST_CARD_VERSION_LABEL: "Version",
  KITS_LIST_CARD_IS_ACTIVE: "Active",
  KITS_LIST_CARD_IS_INACTIVE: "Archived",
  KITS_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the kit",
  KITS_LIST_CARD_MENU_NEW_VERSION: "New version",
  KITS_LIST_CARD_MENU_DEACTIVATE: "Archive",
  KITS_LIST_CARD_MENU_ACTIVATE: "Activate",
  KITS_LIST_CARD_MENU_DELETE: "Delete",
  KITS_LIST_CARD_MENU_CREATE_FROM: "Create from",
  KITS_LIST_CARD_SEE_DETAILS_LINK: "See in details",
  KITS_LIST_CARD_DELETION_MODAL_TITLE: "Removal of the kit\n'{{kitName}}'",
  KITS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the kit '{{kitName}}' ?",
  KITS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the kit",
  KITS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Kit removal successful",
  KITS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the kit. Please retry.",
  KITS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit activated successfully",
  KITS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the activation of the kit. Please retry.",
  KITS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit archived successfully",
  KITS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the archiving of the kit. Please retry.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Creation of a new version of the kit\n'{{kitName}}'",
  KITS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "You are about to create a new version of the kit '{{kitName}}'. Enter the new version number and confirm.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "New version",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Create new version",
  KITS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "New kit version created successfully",
  KITS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the creation of a new version of the kit. Please retry.",
  /* LOGIN */
  LOGIN_TITLE_DESCRIPTION: "Connection to Geeng",
  LOGIN_WELCOME_TITLE: "Nice to see you again",
  LOGIN_WELCOME_SUBTITLE: "Enter your identifier and\npassword",
  LOGIN_LANGUAGE_SELECTOR_LABEL: "Select the language of your choice",
  LOGIN_FORM_ERROR_INVALID_PASSWORD_OR_IDENTIFIER:
    "Invalid identifier or password",
  LOGIN_FORM_IDENTIFIER_LABEL: "Identifier",
  LOGIN_FORM_IDENTIFIER_ERROR_REQUIRED: "Identifier is required",
  LOGIN_FORM_PASSWORD_LABEL: "Password",
  LOGIN_FORM_PASSWORD_ERROR_REQUIRED: "Password is required",
  LOGIN_FORM_SUBMIT_BUTTON: "Log in",
  LOGIN_FORGOTTEN_PASSWORD_BUTTON: "Forgot password ?",
  /* LOGO */
  LOGO_APP_TITLE: "Geeng",
  LOGO_CUSTOMER: "CHU of Rennes",
  title: "Welcome to As We Care",
  /* LOGOUT */
  LOGOUT_TOKEN_EXPIRED_TOAST_MESSAGE:
    "Your session has expired, you need to login again.",
  LOGOUT_SUCCESSFUL_TOAST_MESSAGE: "You are now logged out",
  /* MAIN APP SIDEBAR */
  MAIN_APP_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  MAIN_APP_SIDEBAR_ITEMS_APPLICATION_DPCR: "DPCR",
  MAIN_APP_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  /* MATRICES */
  MATRICES_CF_DNA: "cf-DNA",
  MATRICES_F_DNA: "f-DNA",
  /* QUALITY CONTROL RESULT */
  QUALITY_CONTROL_RESULT_PASS_LABEL: "Compliant",
  QUALITY_CONTROL_RESULT_FAIL_LABEL: "Non Compliant",
  QUALITY_CONTROL_RESULT_WARNING_LABEL: "To watch",
  /* RUNS */
  /* RUNS STATUS */
  RUN_STATUS_VALIDATED: "Validated",
  RUN_STATUS_READY: "Available",
  RUN_STATUS_WAITING: "Waiting",
  RUN_STATUS_FAILED: "Error",
  RUN_STATUS_PROCESSING: "Processing",
  RUN_STATUS_QUEUED: "Queued",
  /* RUNS QC VALIDTION STATUS */
  RUN_QUALITY_CONTROL_VALIDATION_DONE: "Validation des Quality Controls done",
  RUN_QUALITY_CONTROL_VALIDATION_TODO:
    "Validation des Quality Controls to be carried out",
  /* RUNS CONTROLS TYPE */
  TYPE_BLANK_CONTROL: "Blank control",
  TYPE_BLANK_CONTROL_SHORT: "TB",
  TYPE_NEG_CONTROL: "Negative control",
  TYPE_NEG_CONTROL_SHORT: "TN",
  TYPE_POS_CONTROL: "Positive control",
  TYPE_POS_CONTROL_SHORT: "TP",
  QC_CONTROLS_TOOLTIP: "Result for the quality control {{type}} : {{result}}",
  QC_CONTROLS_TOOLTIP_ERROR:
    "An error has occurred during the calculation of the result of this Quality Control.",
  QC_CONTROLS_TOOLTIP_TRUE_zero: "compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_TRUE_one: "compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_TRUE_other: "compliant : {{count}} positive partitions",
  QC_CONTROLS_TOOLTIP_FALSE_zero:
    "non-compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_FALSE_one: "non-compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_FALSE_other:
    "non-compliant : {{count}} positive partitions",
  /* RUNS_IMPORT */
  RUNS_IMPORT_BUTTON_LABEL: "Add new Runs",
  RUNS_IMPORT_TITLE: "Import Runs results files",
  RUNS_IMPORT_TITLE_FOR_SERIE: "Import Runs results files for Serie {{serie}}",
  RUNS_IMPORT_SUBTITLE:
    "Select the concerned serie and then drop or select results files you want to load in the application associating them with one or more operators.",
  RUNS_IMPORT_SUBTITLE_FOR_SERIE:
    "Drop or select results files you want to load in the application associating them with one or more operators.",
  RUNS_IMPORT_DROP_AREA_SUPPORTED_FILES_LABEL:
    "Supported files are only : .xlsx and .xls",
  RUNS_IMPORT_DROP_AREA_SELECT_FILES_BUTTON_LABEL: "Click to select",
  RUNS_IMPORT_DROP_AREA_LABEL: "Results files",
  RUNS_IMPORT_DROP_AREA_INNER_TEXT: "or directly drop a file",
  RUNS_IMPORT_DROP_AREA_INNER_SUBTEXT: "Supported files are",
  RUNS_IMPORT_FORM_RUN_NAME_LABEL: "Run name",
  RUNS_IMPORT_FORM_RUN_ANALYSIS_LABEL: "Analysis",
  RUNS_IMPORT_FORM_RUN_ANALYSIS_NO_OPTION: "No analysis found",
  RUNS_IMPORT_FORM_RUN_ANALYSIS_PLACEHOLDER: "Select an analysis",
  RUNS_IMPORT_FORM_RUN_KIT_BATCH_LABEL: "Kit batch",
  RUNS_IMPORT_FORM_RUN_KIT_BATCH_NO_OPTION: "No kit batch found",
  RUNS_IMPORT_FORM_RUN_KIT_BATCH_PLACEHOLDER: "Select a kit batch",
  RUNS_IMPORT_FORM_SERIE_LABEL: "Serie",
  RUNS_IMPORT_FORM_SERIE_NO_OPTION: "No serie found",
  RUNS_IMPORT_FORM_SERIE_PLACEHOLDER: "Select a serie",
  RUNS_IMPORT_FORM_SUBMIT_BUTTON: "Validate and import results",
  RUNS_IMPORT_FORM_SUCCESS_TOAST_one: "Import of results file successful",
  RUNS_IMPORT_FORM_SUCCESS_TOAST_other:
    "Import of {{count}} results files successful",
  RUNS_IMPORT_BACK_TO_RUNS_LIST_BUTTON: "Back to Runs list",
  /* RUNS LIST */
  RUNS_LIST_TITLE_DESCRIPTION: "Runs list",
  RUNS_LIST_TITLE: "Runs list",
  RUNS_LIST_TABLE_HEADER_TITLE: "All our runs",
  RUNS_LIST_TABLE_HEADER_BADGE_zero: "{{count}} Serie",
  RUNS_LIST_TABLE_HEADER_BADGE_one: "{{count}} Serie",
  RUNS_LIST_TABLE_HEADER_BADGE_other: "{{count}} Series",
  RUNS_LIST_TABLE_HEADER_FILTER_BUTTON: "Filter",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_LABEL: "Filter by analysis",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_PLACEHOLDER: "Select an analysis",
  RUNS_LIST_TABLE_HEADER_FILTER_STATE_LABEL: "Filter by state",
  RUNS_LIST_TABLE_HEADER_FILTER_STATE_PLACEHOLDER: "Select a state",
  RUNS_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Filter by date",
  RUNS_LIST_TABLE_HEADER_FILTER_DATE_PLACEHOLDER: "Select a date",
  RUNS_LIST_TABLE_COLUMN_SERIE: "Series",
  RUNS_LIST_TABLE_COLUMN_SERIE_TOOLTIP: "Number of treated samples",
  RUNS_LIST_TABLE_COLUMN_RUNS: "Runs",
  RUNS_LIST_TABLE_COLUMN_ANALYSIS: "Analysis",
  RUNS_LIST_TABLE_COLUMN_STATE: "Status",
  RUNS_LIST_TABLE_COLUMN_DATE: "Date",
  RUNS_LIST_TABLE_COLUMN_EDIT_RUN_LINK: "See more details about run {{runId}}",
  /* SAMPLE DETAILS */
  SAMPLE_DETAILS_SAMPLE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Sample not found. Back to serie's samples list.",
  SAMPLE_DETAILS_BREADCRUMBS_BACK_LABEL: "Back to list",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_NEXT_SAMPLE: "Next sample",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_BACK_TO_LIST: "Back to list",
  SAMPLE_DETAILS_TITLE_DESCRIPTION: "Details of sample {{sampleId}}",
  SAMPLE_DETAILS_TITLE: "Sample",
  SAMPLE_DETAILS_TITLE_STATUS: "Status :",
  SAMPLE_DETAILS_HEADER_PATHOLOGY: "Pathology",
  SAMPLE_DETAILS_HEADER_SAMPLE_TYPE: "S. Type",
  SAMPLE_DETAILS_HEADER_CELL_QUANTITY: "Cell V.",
  SAMPLE_DETAILS_HEADER_ANALYSES: "Analyses",
  SAMPLE_DETAILS_ACTION_BUTTON_LABEL: "Actions",
  SAMPLE_DETAILS_ACTION_MENU_EXPORT: "Export",
  SAMPLE_DETAILS_EXPORT_MODAL_TITLE: "Export of data",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS: "Informations",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS_SAMPLE_ID: "Sample ID",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_zero: "{{count}} target",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_one: "{{count}} target",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_other: "{{count}} targets",
  SAMPLE_DETAILS_EXPORT_MODAL_SUBMIT_LABEL: "Export",
  SAMPLE_DETAILS_EXPORT_SUCCESS_TOAST_MESSAGE: "Export successfully done",
  SAMPLE_DETAILS_EXPORT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the data export. Please retry.",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS: "Analysis",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS_STATUS: "Status",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL: "QC",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_PASS: "Pass",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_NOT_PASS: "Not pass",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_CHAMBER: "Chamber",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_SAMPLE: "Sample",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_PARTITIONS: "{{count}} part.",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_DNA: "DNA",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_DNA_QUANTITY: "{{quantity}} ng",
  SAMPLE_DETAILS_TABLE_COLUMN_TARGET: "Target",
  SAMPLE_DETAILS_TABLE_COLUMN_BLANK_CONTROL: "TB",
  SAMPLE_DETAILS_TABLE_COLUMN_NEG_CONTROL: "TN",
  SAMPLE_DETAILS_TABLE_COLUMN_POS_CONTROL: "TP",
  SAMPLE_DETAILS_TABLE_COLUMN_PARTITIONS: "PART.",
  SAMPLE_DETAILS_TABLE_COLUMN_VAF: "VAF",
  SAMPLE_DETAILS_TABLE_COLUMN_SENSITIVITY: "Max Sens.",
  SAMPLE_DETAILS_TABLE_COLUMN_UNCERTAINTY: "Uncert.",
  SAMPLE_DETAILS_TABLE_COLUMN_INTERPRETATION: "Interpretation",
  SAMPLE_DETAILS_TABLE_COLUMN_FLAGGED_TOOLTIP_DISABLED:
    "Impossible to declare a non-mutated target as a variant of interest",
  SAMPLE_DETAILS_TABLE_VALIDATE_RUN_RESULT: "Validate",
  SAMPLE_DETAILS_VALIDATED: "Validated",
  SAMPLE_DETAILS_UPDATE_RUN_SUCCESS_TOAST_MESSAGE:
    "Information updated successfully",
  SAMPLE_DETAILS_UPDATE_RUN_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update. Please retry.",
  /* SAMPLE INTERPRETATION */
  UNKNOWN: "Not interpreted",
  NON_CONTRIBUTIVE: "Not contributive",
  UNMUTATED: "Not mutated",
  MUTATED: "Mutated",
  /* SAMPLE POSITIVITY */
  SAMPLE_POSITIVITY_CANDIDATE: "Candidate",
  SAMPLE_POSITIVITY_POSITIVE: "Positive",
  /* SAMPLE PRIORITY */
  SAMPLE_PRIORITY_DEFAULT: "Routine",
  SAMPLE_PRIORITY_URGENT: "Urgent",
  /* SAMPLE STATUS */
  SAMPLE_STATUS_READY: "To do",
  SAMPLE_STATUS_PROCESSING: "In progress",
  SAMPLE_STATUS_VALIDATED: "Validated",
  SAMPLE_STATUS_ERROR: "Error",
  /* SAMPLE TYPE */
  SAMPLE_TYPE_F_DNA: "free DNA",
  SAMPLE_TYPE_F_DNA_SHORT: "F",
  SAMPLE_TYPE_CF_DNA: "cell-free DNA",
  SAMPLE_TYPE_CF_DNA_SHORT: "CF",
  /* SAMPLESHEETS LIST */
  SAMPLESHEETS_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the samplesheet",
  SAMPLESHEETS_LIST_CARD_MENU_DELETE: "Remove",
  SAMPLESHEETS_LIST_CARD_MENU_DOWNLOAD: "Download",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_TITLE: "Removal of the samplesheet",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the samplesheet\n{{- samplesheetName}} ?",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON:
    "Remove the samplesheet",
  SAMPLESHEETS_LIST_DELETION_SUCCESS_TOAST_MESSAGE:
    "Samplesheet deleted successfully",
  SAMPLESHEETS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the samplesheet. Please retry.",
  /* SERIES CREATION */
  SERIES_CREATION_TITLE_DESCRIPTION: "Serie creation",
  SERIES_CREATION_TITLE: "Serie creation",
  SERIES_CREATION_SUBTITLE:
    "To begin, name the serie to initialize it. You will then be able to load its samplesheets files and after, Runs results files.",
  SERIES_CREATION_FORM_INFORMATIONS_TITLE: "Main informations of the serie",
  SERIES_CREATION_FORM_IDENTIFIER_LABEL: "Name",
  SERIES_CREATION_FORM_SUBMIT: "Create the serie",
  SERIES_CREATION_BACK_TO_SERIES_LIST_BUTTON: "Back to series list",
  SERIES_CREATION_FORM_SUCCESS_TOAST: "Serie successfully created",
  /* SERIE DETAILS */
  SERIES_DETAILS_TITLE_DESCRIPTION: "Details of the serie '{{serieName}}'",
  SERIES_DETAILS_SERIE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Serie not found. Back to series list.",
  SERIES_DETAILS_RUN_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Run not found for the serie. Back to serie's details page.",
  /* SERIE DETAILS NO RESULTS*/
  SERIES_DETAILS_NO_RESULTS_TITLE: "Serie details",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_TITLE: "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_IMPORT_BUTTON_LABEL:
    "Add Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_zero:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_one:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_other:
    "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_ADD_DESCRIPTION:
    "No Samplesheet transmitted for this serie.",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_TITLE: "Results file",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_RESULTS_NB: "Results file",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_ADD_DESCRIPTION:
    "No results file transmitted for this serie.",
  /* SERIE DETAILS CONTROL VALIDATION*/
  SERIES_DETAILS_CONTROL_VALIDATION_TITLE: "Controls validation",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_LABEL: "Runs of the serie",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_zero: "Sample",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_one: "Sample",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_other: "Samples",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TITLE: "Analysis, Kit & Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_ANALYSIS_COLUMN_TITLE:
    "Analysis",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_SUPPLIER_COLUMN_TITLE:
    "Supplier",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_CHIP_COLUMN_TITLE:
    "Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_KIT_COLUMN_TITLE: "Kit",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_TARGETS_COLUMN_TITLE:
    "Targets",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_TITLE:
    "Global Quality Control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SUBTITLE:
    "Chip verification",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_zero: "{{count}} Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_one: "{{count}} Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_other:
    "{{count}} Chips",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_INDEX:
    "Chip n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_VALIDATION_TOOLTIP:
    "Validate or not the Quality Controls for the chip n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_TABLE_COLUMN_HEADER_ID:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_zero:
    "{{count}} partition for this sample",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_one:
    "{{count}} partition for this sample",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_other:
    "{{count}} partitions for this sample",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_zero:
    "{{count}} expected partition to satisfy the chip quality control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_one:
    "{{count}} expected partition to satisfy the chip quality control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_other:
    "{{count}} expected partitions to satisfy the chip quality control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Chip quality control updated successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the chip quality control. Please retry.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Quality control result of the sample {{sampleId}} updated successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the quality control result of the sample {{sampleId}}. Please retry.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TITLE:
    "Control Quality of Witnesses",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_zero:
    "{{count}} Witness",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_one:
    "{{count}} Witness",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_other:
    "{{count}} Witnesses",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_TYPE_COLUMN_TITLE:
    "Control type",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_BATCH_NUMBER:
    "Batch N° : {{batchNumber}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_ID_COLUMN_TITLE:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_PARTITIONS_NB_COLUMN_TITLE:
    "Partitions nb",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Quality control result of the witness {{sampleId}} updated successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the quality control result of the witness {{sampleId}}. Please retry.",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON:
    "Validate controls of the run",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_TITLE:
    "Validation of the run's quality controls",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBTITLE:
    "You are about to validate the run's quality controls. You can leave a comments to provide further information.",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUMMARY_INFORMATIONS:
    "Informations",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_ANALYSIS: "Analysis",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_zero:
    "Chip number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_one: "Chip number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_other:
    "Chip number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_zero:
    "Witness number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_one:
    "Witness number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_other:
    "Witnesses number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_VALIDATION_DATE:
    "Validation date",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_OPERATOR: "By",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_COMMENTS: "Comments",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBMIT:
    "Validate the controls of the run",
  SERIES_DETAILS_CONTROL_VALIDATED:
    "Quality controls validated on {{date}} by {{by}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Validation of the run's quality controls done successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the validation of the run's quality controls. Please retry.",
  /* SERIES DETAIL SAMPLES LIST */
  SERIES_DETAILS_SAMPLES_LIST_TITLE: "Serie",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE: "All samples",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_zero: "{{count}} Sample",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_one: "{{count}} Sample",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_other: "{{count}} Samples",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_LABEL: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_NO_OPTION_MESSAGE:
    "No Sample ID found",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_LABEL: "Pathology",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_NO_OPTION_MESSAGE:
    "Aucun pathology found",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_STATUS_LABEL: "Status",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_RESET_LABEL: "Reset filters",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ID: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_TITLE: "Comments",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS:
    "Sample informations",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_SAMPLE_ID:
    "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_PATHOLOGY:
    "Pathology",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_ANALYSES:
    "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_COMMENTS: "Comments",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_FOLLOW_LINK: "Visit",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PRIORITY: "Priority",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PATHOLOGY: "Pathology",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_SAMPLE_TYPE: "S. Type",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_CELL_QUANTITY: "Cell V.",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ANALYSES: "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_ANALYSES_TOOLTIP:
    "Interpretation for analysis {{analysisName}} : {{interpretation}}",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_POSITIVITY: "Positivity",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_STATUS: "Status",
  /* SERIES LIST */
  SERIES_LIST_TITLE_DESCRIPTION: "Series list",
  SERIES_LIST_TITLE: "Series list",
  SERIES_LIST_CREATE_SERIE_BUTTON: "Create new serie",
  SERIES_LIST_TABLE_HEADER_TITLE: "All our series",
  SERIES_LIST_TABLE_HEADER_BADGE_zero: "{{count}} serie",
  SERIES_LIST_TABLE_HEADER_BADGE_one: "{{count}} serie",
  SERIES_LIST_TABLE_HEADER_BADGE_other: "{{count}} series",
  SERIES_LIST_TABLE_HEADER_FILTER_BUTTON: "Filter",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Filter by date",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_PLACEHOLDER: "Select a date",
  SERIES_LIST_CARD_DATE_LABEL: "Date",
  SERIES_LIST_CARD_EXPECTED_SAMPLES_LABEL: "Expected samples",
  SERIES_LIST_CARD_TREATED_SAMPLES_LABEL: "Treated samples",
  SERIES_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the serie",
  SERIES_LIST_CARD_MENU_ADD_NEW_SAMPLESHEET: "Add samplesheet",
  SERIES_LIST_CARD_SEE_DETAILS_LINK: "See in details",
  SERIES_LIST_CARD_DELETION_MODAL_TITLE:
    "Removal of the serie\n'{{serieName}}'",
  SERIES_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the serie '{{serieName}}' ?",
  SERIES_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the serie",
  SERIES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Serie deleted successfully",
  SERIES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the serie. Please retry.",
  /* SERIES SAMPLESHEETS IMPORT */
  SERIES_SAMPLESHEETS_IMPORT_TITLE: "Import samplesheet files for a Serie",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_FOR_SERIE:
    "Import samplesheet files for Serie {{serie}}",
  SERIES_SAMPLESHEETS_IMPORT_SUBTITLE:
    "Drop or select samplesheets files and link them to one or more operators.",
  SERIES_SAMPLESHEETS_IMPORT_BACK_TO_SERIES_BUTTON: "Back to Series list",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_LABEL: "Operator(s)",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_NO_OPTION:
    "No operator found",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_PLACEHOLDER:
    "Select operator(s)",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SUPPORTED_FILES_LABEL:
    "Supported files are only : .xlsx et .xls",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SELECT_FILES_BUTTON_LABEL:
    "Click to select",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_LABEL: "Samplesheets files",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_TEXT: "or drop a file directly",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_SUBTEXT: "Supported files are",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUBMIT_BUTTON:
    "Validate and import samplesheets",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_one:
    "Import of samplesheet file successful",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_other:
    "Import od {{count}} samplesheets files successful",
  SERIES_SAMPLESHEETS_IMPORT_BACK_TO_SERIES_LIST_BUTTON: "Back to Series list",
  /* SOFTWARES LITS */
  SOFTWARES_LIST_TITLE_DESCRIPTION: "Softwares list",
  SOFTWARES_LIST_TITLE: "Softwares list",
  SOFTWARES_LIST_SEARCH_SOFTWARE_LABEL: "Search software",
  SOFTWARES_LIST_COUNT_LABEL_zero: "{{count}} software",
  SOFTWARES_LIST_COUNT_LABEL_one: "{{count}} software",
  SOFTWARES_LIST_COUNT_LABEL_other: "{{count}} softwares",
  SOFTWARES_LIST_CONTACT_LABEL:
    "You want to integrate a new software in the application in order to operate new results files ? Contact the Geeng team",
  SOFTWARES_LIST_CONTACT_BUTTON_LABEL: "Contact",
};
